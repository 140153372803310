import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORGANISATIONS } from './graphql';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { Drawer } from 'packages/shared/core';
import { MoveParticipant, UpdateParticipant, UsersTable, MailParticipant } from './parts';
import { useQuery } from '@apollo/react-hooks';

const ParticipantTable = (props) => {
    const { participants, mailOpen, onClose, filter } = props;
    const { t } = useTranslation();
    const history = useNavigate();
    const [currentUser, setCurrentUser] = useState('');
    const [anchorEl, setAnchorEl] = useState(false);
    const [updateParticipantOpen, setUpdateParticipantOpen] = useState(false);
    const [moveParticipantOpen, setMoveParticipantOpen] = useState(false);
    const [mailParticipants, setMailParticipants] = useState([]);

    const { data: organisations = {} } = useQuery(ORGANISATIONS);

    const allOrganisations = organisations.getOrganisations;

    const handleMenuClick = (event, r) => {
        setAnchorEl(event.currentTarget);

        setCurrentUser(r[0]);
    };

    const handleClose = () => {
        setAnchorEl(false);
        setMoveParticipantOpen(false);
        setUpdateParticipantOpen(false);
    };

    const handleUpdateClick = () => {
        setUpdateParticipantOpen(true);
    };

    const handleMoveClick = () => {
        setMoveParticipantOpen(true);
    };

    const handleRowClick = (id) => {
        if (id) {
            history(`/participant/${id}`);
        }
    };

    const onCheckboxClick = (event) => {
        setMailParticipants(event);
    };

    return (
        <React.Fragment>
            <UsersTable
                layout="fixed"
                filter={filter}
                rows={participants}
                onOptionsClick={handleMenuClick}
                onRowClick={handleRowClick}
                onCheckboxClick={onCheckboxClick}
            />
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ zIndex: '0' }}
            >
                <MenuItem onClick={handleUpdateClick}>{t('GENERAL.CHANGE')}</MenuItem>
                <MenuItem onClick={handleMoveClick}>{t('GENERAL.MOVE')}</MenuItem>
            </Menu>

            <Drawer size="lg" position="absolute" open={moveParticipantOpen}>
                <MoveParticipant
                    onClose={handleClose}
                    initial={currentUser}
                    organisations={allOrganisations}
                />
            </Drawer>
            <Drawer position="absolute" open={updateParticipantOpen}>
                <UpdateParticipant initial={currentUser} onClose={handleClose} />
            </Drawer>

            <Drawer position="absolute" open={mailOpen}>
                <MailParticipant onClose={onClose} initial={mailParticipants} />
            </Drawer>
        </React.Fragment>
    );
};

export default ParticipantTable;
