import { gql } from 'apollo-boost';

export const ORGANISATIONS = gql`
    query getPaginatedOrganisations($page: Int!) {
        getPaginatedOrganisations(page: $page) {
            items {
                id
                name
                amountFormParticipants
                coaches {
                    id
                    firstName
                    lastName
                }
                name
                locations {
                    id
                    name
                    formUrl
                    groups {
                        id
                        name
                        formUrl
                        subgroups {
                            id
                            name
                            formUrl
                        }
                    }
                }
            }
        }
    }
`;

export const ORGANISATION = gql`
    query getOrganisationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getOrganisationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
            coaches {
                id
                firstName
                lastName
            }
            name
            locations {
                id
                name
                formUrl
                groups {
                    id
                    name
                    formUrl
                    subgroups {
                        id
                        name
                        formUrl
                    }
                }
            }
        }
    }
`;

export const CREATE_ORGANISATION = gql`
    mutation createOrganisation($createOrganisation: CreateOrganisationInput!) {
        createOrganisation(createOrganisation: $createOrganisation) {
            id
            name
            coaches {
                id
                firstName
                lastName
            }
            name
            locations {
                id
                name
                formUrl
                groups {
                    id
                    name
                    formUrl
                    subgroups {
                        id
                        name
                        formUrl
                    }
                }
            }
        }
    }
`;

export const UPDATE_ORGANISATION = gql`
    mutation updateOrganisation($updateOrganisation: UpdateOrganisationInput!) {
        updateOrganisation(updateOrganisation: $updateOrganisation) {
            id
            name
            coaches {
                id
                firstName
                lastName
            }
            name
            locations {
                id
                name
                formUrl
                groups {
                    id
                    name
                    formUrl
                    subgroups {
                        id
                        name
                        formUrl
                    }
                }
            }
        }
    }
`;

export const COACHES = gql`
    query getCoaches {
        getCoaches {
            firstName
            lastName
            id
        }
    }
`;
