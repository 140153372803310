import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '50px'
    },
    initials: {
        minWidth: '180px',
        height: '180px',
        background: theme.palette.tertiary[500],
        fontFamily: theme.mind.titleFont,
        borderRadius: '18px',
        fontWeight: '500',
        letterSpacing: '4.5px',
        color: theme.mind.white[500],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '50px',
        textTransform: 'uppercase',
        fontSize: '75px'
    },
    infoBlock: {
        width: '100%',
        display: 'grid',
        gridTemplateRows: '1fr 1fr 1fr 1fr',
        gridTemplateColumns: '1fr 1fr',
        gridAutoFlow: 'column'
    },
    infoRow: {
        background: theme.mind.secondaryBackgroundColor[500],
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        borderBottom: '1px solid #FFF',
        borderRight: '1px solid #FFF'
    },
    infoRowTitle: {
        marginBottom: '0',
        letterSpacing: '0',
        textTransform: 'capitalize',
        fontSize: '15px'
    },
    rowText: {
        fontSize: '15px'
    }
}));

const InfoBlock = (props) => {
    const { initial, rows } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const titles = [
        t('GENERAL.NAME'),
        t('GENERAL.MAIL'),
        t('GENERAL.ORGANISATION'),
        t('GENERAL.BIRTHDATE')
    ];

    // const rows = ['Nick Put', 'n.put@wearedoop.com', 'Doop Company', '01-01-1990'];

    return (
        <div className={classes.root}>
            <div className={classes.initials}>
                {initial && initial.firstName[0] + initial.lastName[0]}
            </div>
            <div className={classes.infoBlock}>
                {titles.map((title, idx) => (
                    <div key={idx} className={classes.infoRow}>
                        <Typography className={classes.infoRowTitle} variant="subtitle1">
                            {title}
                        </Typography>
                    </div>
                ))}
                {rows.map((row, idx) => (
                    <div key={idx} className={classes.infoRow}>
                        <Typography className={classes.rowText}>{row}</Typography>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InfoBlock;
