import {
    isNil,
    isEmpty,
    isNumber,
    isInteger,
    isString,
    isPassword,
    isEmail,
    isPhoneNumber,
    isMeasurementNumber,
    isDate,
    path
} from './lib';
import { NUMBER, STRING, INTEGER } from './types';
import { PASSWORD, EMAIL, PHONE_NUMBER, DATE, MEASUREMENT_NUMBER } from './formats';

export const shouldCheckRequired = (propertySchema) => {
    return path(['required'], propertySchema) === true;
};

export const shouldCheckMaxLength = (propertySchema) => {
    return isNumber(path(['maxLength'], propertySchema));
};

export const shouldCheckMinLength = (propertySchema) => {
    return isNumber(path(['minLength'], propertySchema));
};

export const shouldCheckType = (propertySchema) => {
    return isString(path(['type'], propertySchema));
};

export const shouldCheckFormat = (propertySchema) => {
    return isString(path(['format'], propertySchema));
};

export const shouldCheckCouple = (propertySchema) => {
    return isString(path(['couple'], propertySchema));
};

export const shouldCheckMinValue = (propertySchema) => {
    return isNumber(path(['minValue'], propertySchema));
};

export const shouldCheckRequiredFields = (propertySchema) => {
    return path(['requiredFields'], propertySchema);
};

export const checkRequired = (propertySchema, propertyValue) => {
    return (
        !shouldCheckRequired(propertySchema) ||
        !(isNil(propertyValue) || isEmpty(propertyValue) || !propertyValue)
    );
};

export const checkMaxLength = (propertySchema, propertyValue) => {
    return (
        !shouldCheckMaxLength(propertySchema) ||
        (!isNil(propertyValue) &&
            isNumber(propertyValue.length) &&
            propertyValue.length <= propertySchema.maxLength)
    );
};

export const checkMinLength = (propertySchema, propertyValue) => {
    return (
        !shouldCheckMinLength(propertySchema) ||
        (!isNil(propertyValue) &&
            isNumber(propertyValue.length) &&
            propertyValue.length >= propertySchema.minLength)
    );
};

export const checkMinValue = (propertySchema, propertyValue) => {
    return (
        !shouldCheckMinValue(propertySchema) ||
        (!isNil(propertyValue) && propertyValue >= propertySchema.minValue)
    );
};

export const checkRequiredFields = (propertySchema, propertyValue) => {
    let empty = false;

    propertySchema.requiredFields &&
        propertySchema.requiredFields.forEach((i) => {
            if (propertyValue && propertyValue.length) {
                propertyValue.forEach((b) => {
                    if (!b[i]) empty = true;
                });
            }
        });

    return !shouldCheckRequiredFields(propertySchema) || !empty;
};

export const checkType = (propertySchema, propertyValue) => {
    return (
        !shouldCheckType(propertySchema) ||
        (propertySchema.type === STRING && (isString(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.type === NUMBER && (isNumber(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.type === INTEGER && (isInteger(propertyValue) || isNil(propertyValue)))
    );
};

export const checkFormat = (propertySchema, propertyValue) => {
    return (
        !shouldCheckFormat(propertySchema) ||
        (propertySchema.format === PASSWORD &&
            (isPassword(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.format === EMAIL && (isEmail(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.format === MEASUREMENT_NUMBER &&
            (isMeasurementNumber(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.format === PHONE_NUMBER &&
            (isPhoneNumber(propertyValue) || isNil(propertyValue))) ||
        (propertySchema.format === DATE && (isDate(propertyValue) || isNil(propertyValue)))
    );
};

export const checkCouple = (propertySchema, propertyValue, entity) => {
    return !shouldCheckCouple(propertySchema) || propertyValue === entity[propertySchema.couple];
};
