import React, { useState, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import { Checkbox as MCheckbox, FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        verticalAlign: 'text-bottom'
    },
    checkboxRoot: {
        color: 'rgba(0,0,0,0.38)',
        padding: `${theme.spacing(0.875)}px ${theme.spacing(1.125)}px`
    },
    noPadding: {
        padding: 0
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
        border: '2px solid currentColor',
        borderRadius: '2px'
    },
    iconSVG: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.contrastText
    },
    iconChecked: {
        backgroundColor: theme.palette.primary[500],
        borderColor: theme.palette.primary[500]
    },
    iconCheckedSecondary: {
        backgroundColor: theme.palette.primary[500]
    },
    label: {
        marginLeft: theme.spacing(1.25),
        color: '#4E5975',
        padding: `${theme.spacing(0.75)}px 0`,
        lineHeight: '28px',
        fontSize: '14px'
    },
    labelRoot: {
        alignItems: 'flex-start',
        userSelect: 'none'
    },
    noNegativeMargin: {
        marginLeft: 0
    },
    disabled: {
        opacity: '0.2'
    }
}));

const Checkbox = (props) => {
    const {
        label = false,
        initialValue = null,
        form = false,
        name,
        arrayName,
        loading,
        color = 'secondary',
        margin = 'normal',
        disabled
    } = props;
    const classes = useStyles();

    const [checkedState, setCheckedState] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: initialValue });
        setCheckedState(!!initialValue);
        // eslint-disable-next-line
    }, [initialValue]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form.errors, name]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.checked });

        if (props.onChange) {
            const obj = {
                value: event.target.checked,
                key: name || ''
            };
            if (arrayName) obj.arrayName = arrayName;
            props.onChange(obj);
        } else {
            setCheckedState(event.target.checked);
        }
    };

    return (
        <FormControl
            component="fieldset"
            className={classNames(classes.formControl, {
                [classes.loading]: loading
            })}
            error={error}
            margin={margin}
        >
            <FormControlLabel
                className={classNames(classes.labelRoot, {
                    [classes.noNegativeMargin]: margin === 'none'
                })}
                disabled={disabled}
                control={
                    <MCheckbox
                        color={color}
                        classes={{
                            root: classNames(classes.checkboxRoot, {
                                [classes.noPadding]: margin === 'none'
                            }),
                            checked: classes.checked
                        }}
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                            <span
                                className={classNames(
                                    classes.icon,
                                    {
                                        [classes.iconChecked]: checkedState
                                    },
                                    {
                                        [classes.iconCheckedSecondary]:
                                            checkedState && color === 'secondary'
                                    },
                                    {
                                        [classes.disabled]: disabled
                                    }
                                )}
                            >
                                <CheckIcon className={classes.iconSVG} />
                            </span>
                        }
                        onChange={handleChange}
                        checked={checkedState}
                    />
                }
                label={label}
                classes={{
                    label: classNames(classes.label, {
                        [classes.checkedLabel]: checkedState
                    })
                }}
            />
            {errorMessage && <FormHelperText>{error && errorMessage}</FormHelperText>}
        </FormControl>
    );
};

export default Checkbox;
