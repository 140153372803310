import { gql } from 'apollo-boost';

export const SUBGROUP = gql`
    query getSubgroupById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getSubgroupById(id: $id, page: $page, ascending: $ascending) {
            name
            averageEnergizersPerResult {
                averageEnergizerFeeding
                averageEnergizerHome
                averageEnergizerHumour
                averageEnergizerMeaning
                averageEnergizerMoving
                averageEnergizerPassion
                averageEnergizerRelaxing
                averageEnergizerSleeping
                averageEnergizerSocials
                averageEnergizerWork
                averageTotal
            }
            formParticipants {
                items {
                    firstName
                    lastName
                    birthdate
                    id
                    email
                    results {
                        date
                    }
                }
            }
        }
    }
`;

export const ORGANIZATION = gql`
    query getOrganisationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getOrganisationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
        }
    }
`;

export const LOCATION = gql`
    query getLocationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getLocationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
        }
    }
`;

export const GROUP = gql`
    query getGroupById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getGroupById(id: $id, page: $page, ascending: $ascending) {
            id
            name
        }
    }
`;

export const ORGANISATIONS = gql`
    query getOrganisations {
        getOrganisations {
            id
            name
            locations {
                id
                name
                groups {
                    id
                    name
                    subgroups {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const UPDATE_PARTICIPANT = gql`
    mutation updateFormParticipant($updateFormParticipant: UpdateFormParticipantInput!) {
        updateFormParticipant(updateFormParticipant: $updateFormParticipant) {
            id
            birthdate
            email
            firstName
            lastName
        }
    }
`;

export const MOVE_PARTICIPANT = gql`
    mutation moveFormParticipant($moveFormParticipantDto: MoveFormParticipantInput!) {
        moveFormParticipant(moveFormParticipantDto: $moveFormParticipantDto) {
            id
            organisation {
                id
                name
            }
        }
    }
`;
