import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tableHead: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        paddingBottom: '10px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    tableHeadCell: {
        width: '33.3%',
        textTransform: 'uppercase',
        color: theme.palette.tertiary[500],
        letterSpacing: '1px',
        fontSize: '12px',
        fontFamily: theme.mind.titleFont,
        fontWeight: 'bold',

        '&:nth-of-type(2)': {
            paddingLeft: '45px'
        }
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    checkboxHead: {
        width: '0',
        position: 'absolute',
        border: 'none',
        left: '0',
        top: '-12px',
        padding: '0'
    }
}));

const UsersTableHead = (props) => {
    const { onSelectAllClick, numSelected, query: queryObj = {}, rowCount } = props;
    const { pagination = {} } = queryObj;

    const { t } = useTranslation();
    const classes = useStyles();

    const tableHead = [
        { label: t('GENERAL.PARTICIPANT') },
        { label: t('GENERAL.NUMBEROFTESTS') },
        { label: t('GENERAL.LATESTTEST'), order: true }
    ];

    const handleChangeRowsPerPage = (event) => {
        if (queryObj.setQuery)
            queryObj.setQuery({
                pagination: {
                    page: 1,
                    direction: pagination.direction ? pagination.direction === 'false' : false
                }
            });
    };

    return (
        <div className={classes.tableHead}>
            <div className={classes.tableRow}>
                <div className={classes.checkboxHead}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </div>
                {tableHead.map((headCell, idx) => (
                    <div
                        key={idx}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        className={classes.tableHeadCell}
                    >
                        {headCell.label || ''}
                        {headCell.order === true ? (
                            <TableSortLabel
                                active
                                direction={
                                    pagination.direction
                                        ? pagination.direction === 'false'
                                            ? 'desc'
                                            : 'asc'
                                        : 'asc'
                                }
                                onClick={handleChangeRowsPerPage}
                            >
                                <div className={classes.visuallyHidden} />
                            </TableSortLabel>
                        ) : (
                            ''
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UsersTableHead;
