import React from 'react';
import { SelectField } from 'packages/shared/core';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { LOCATIONS } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    select: {
        maxWidth: 'calc(33.3% - 30px)',
        width: '100%',
        marginRight: '30px'
    }
}));

const LocationField = (props) => {
    const { form, id, onChange } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: locations = {} } = useQuery(LOCATIONS, {
        variables: {
            organisationId: id
        }
    });
    const locationList = locations.getLocationsByOrganisationId;

    return (
        <React.Fragment>
            <SelectField
                label={t('PARTICIPANT.LOCATION')}
                className={classes.select}
                emptyField
                options={
                    locationList &&
                    locationList.map((o) => {
                        return {
                            label: o.name,
                            value: o.id
                        };
                    })
                }
                name="location"
                form={form}
                readOnly={
                    !!((locationList && locationList.length === 0) || locationList === undefined)
                }
                onChange={onChange}
            />
        </React.Fragment>
    );
};

export default LocationField;
