import { gql } from 'apollo-boost';

export const CURRENT_USER = gql`
    query getCurrentUser {
        getCurrentUser {
            role
            id
            firstName
            lastName
        }
    }
`;
