import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    centered: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

const Loader = (props) => {
    const classes = useStyles();
    const { centered = false, color = 'primary' } = props;

    return (
        <div className={classNames({ [classes.centered]: centered })}>
            <CircularProgress color={color} />
        </div>
    );
};

export default Loader;
