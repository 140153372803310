import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Select as MSelect } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    label: {
        fontSize: '16px',
        position: 'relative'
    },
    select: {
        padding: '1px',
        minHeight: '56px',
        fontSize: '16px'
    },
    loading: {
        position: 'absolute',
        zIndex: '99',
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity .2s'
    },
    activeLoading: {
        pointerEvents: 'visible',
        opacity: 1
    }
}));

const SelectField = (props) => {
    const {
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        onChange,
        options = [],
        label,
        className,
        name,
        form = false,
        emptyField = false,
        hint,
        initialValue = '',
        loading = false,
        readOnly = false
    } = props;
    const classes = useStyles();

    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [option, setOption] = useState('');

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form.errors, name]);

    useEffect(() => {
        if (initialValue && !loading) {
            setOption(initialValue);
            if (onChange) onChange(initialValue);
            if (form) {
                form.onFieldChange({ key: name, value: initialValue });
            }
        }
        // eslint-disable-next-line
    }, [loading]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.value });
        setOption(event.target.value.toString());
        if (onChange) onChange(event.target.value);
    };

    if (!name) return 'NO NAME';
    return (
        <FormControl
            variant="outlined"
            error={error}
            className={classNames(classes.root, className)}
            margin="normal"
        >
            <div
                className={classNames(classes.loading, {
                    [classes.activeLoading]: loading
                })}
            >
                <Loader />
            </div>
            <InputLabel id={`label-${name}`}>
                <span className={classes.label}>{label}</span>
            </InputLabel>
            <MSelect
                className={classes.select}
                value={option}
                onChange={handleChange}
                labelId={`label-${name}`}
                id={`select-${name}`}
                label={label}
                readOnly={readOnly}
                disabled={readOnly}
            >
                {emptyField === false && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {options &&
                    options.map((option, idx) => (
                        <MenuItem key={idx} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </MenuItem>
                    ))}
            </MSelect>
            {error && <FormHelperText>{error && errorMessage}</FormHelperText>}
            {hint && <FormHelperText>{hint}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
