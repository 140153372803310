import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'none',
        marginTop: '16px',
        marginBottom: '8px'
    },
    disableMargin: {
        marginTop: 0,
        marginBottom: 0
    },
    fullWidth: {
        width: '100%'
    },
    smallFont: {
        fontSize: '14px'
    }
}));

const ButtonComponent = (props) => {
    const {
        label = '',
        fullWidth = false,
        size = 'large',
        variant = 'contained',
        className,
        disableMargin = false,
        color = 'primary',
        disabled = false,
        ...otherProps
    } = props;
    const classes = useStyles();

    //function
    const handleClick = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <Button
            variant={variant}
            onClick={handleClick}
            className={classNames(
                classes.root,
                { [classes.fullWidth]: fullWidth },
                { [classes.disableMargin]: disableMargin },
                { [classes.smallFont]: size === 'small' },
                className
            )}
            color={color}
            size={size}
            disabled={disabled}
            disableElevation
            {...otherProps}
        >
            {label}
        </Button>
    );
};

export default ButtonComponent;
