import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField, DateField } from 'packages/shared/core';
import Close from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { UPDATE_RESULT } from '../../graphql';
import { useForm } from 'hooks';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    },
    button: {
        marginLeft: '10px',

        '&:hover': {
            background: theme.palette.primary[900]
        }
    }
}));

//queries
const UpdateMeasurement = (props) => {
    const { onClose, initial } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const initialData = initial[0];
    const [form, submitResultsForm] = useForm({
        date: {
            required: true
        },
        energizerSleeping: {
            required: true,
            format: 'measurement-number'
        },
        energizerFeeding: {
            required: true,
            format: 'measurement-number'
        },
        energizerHome: {
            required: true,
            format: 'measurement-number'
        },
        energizerHumour: {
            required: true,
            format: 'measurement-number'
        },
        energizerMeaning: {
            required: true,
            format: 'measurement-number'
        },
        energizerMoving: {
            required: true,
            format: 'measurement-number'
        },
        energizerPassion: {
            required: true,
            format: 'measurement-number'
        },
        energizerRelaxing: {
            required: true,
            format: 'measurement-number'
        },
        energizerSocials: {
            required: true,
            format: 'measurement-number'
        },
        energizerWork: {
            required: true,
            format: 'measurement-number'
        }
    });

    const [updateResult] = useMutation(UPDATE_RESULT, {
        refetchQueries: ['getFormParticipantById']
    });

    const handleSubmit = () => {
        submitResultsForm()
            .then((r) => {
                updateResult({
                    variables: {
                        updateResult: {
                            ...r,
                            date: format(new Date(r.date), 'yyyy-MM-dd'),
                            id: initialData.id
                        }
                    }
                }).then((resp) => {
                    if (!resp.errors) {
                        handleSuccess();
                    } else {
                        handleError();
                    }
                });
            })
            .catch((e) => handleError());
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('PARTICIPANT.SUCCESCHANGES')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('PARTICIPANT.CANCELED')
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {t('PARTICIPANT.UPDATEMEASUREMENT')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <Typography variant="body2">Datum</Typography>
            <DateField
                multiline
                fullWidth
                label="Datum"
                name="date"
                form={form}
                type="date"
                initialValue={initialData && initialData.date}
            />
            <Typography variant="body2">Energizers</Typography>
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.SLEEP')}
                name="energizerSleeping"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerSleeping
                        ? initialData.energizerSleeping.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.NUTRITION')}
                name="energizerFeeding"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerFeeding
                        ? initialData.energizerFeeding.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.MOVING')}
                name="energizerMoving"
                type="number"
                clearField
                form={form}
                initialValue={
                    initialData && initialData.energizerMoving
                        ? initialData.energizerMoving.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.HUMOR')}
                name="energizerHumour"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerHumour
                        ? initialData.energizerHumour.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.PASSION')}
                name="energizerPassion"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerPassion
                        ? initialData.energizerPassion.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.RELAX')}
                name="energizerRelaxing"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerRelaxing
                        ? initialData.energizerRelaxing.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.CONTACT')}
                name="energizerSocials"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerSocials
                        ? initialData.energizerSocials.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.HOME')}
                name="energizerHome"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerHome
                        ? initialData.energizerHome.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.WORK')}
                name="energizerWork"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerWork
                        ? initialData.energizerWork.toFixed(1)
                        : 0
                }
            />
            <TextField
                multiline
                fullWidth
                label={t('SUMMARY.MEANING')}
                name="energizerMeaning"
                type="number"
                form={form}
                clearField
                initialValue={
                    initialData && initialData.energizerMeaning
                        ? initialData.energizerMeaning.toFixed(1)
                        : 0
                }
            />

            <div className={classes.buttonsWrapper}>
                <Button onClick={onClose} variant="outlined" color="primary">
                    {t('GENERAL.BACK')}
                </Button>

                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('PARTICIPANT.UPDATEMEASUREMENT')}
                </Button>
            </div>
        </div>
    );
};

export default UpdateMeasurement;
