import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import ParticipantTable from 'components/ParticipantTable';
import Table from 'packages/shared/Table';
import { GROUPS, LOCATION, ORGANIZATION } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import Summary from 'packages/shared/Summary';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Loader } from 'packages/shared/core';
import { useFilter } from 'hooks';

const LocationOverview = (props) => {
    const { t } = useTranslation();
    const params = useParams();
    const history = useNavigate();
    const [groupRows, setGroupRows] = useState([]);
    const tableHead = [t('GENERAL.GROUP'), t('GENERAL.PARTICIPANTS')];
    const [participants, setParticipants] = useState([]);
    const [mailOpen, setMailOpen] = useState(false);

    const filter = useFilter({
        pagination: { sortProperties: ['state'], direction: false }
    });

    // queries
    const { data: location = {}, loading } = useQuery(LOCATION, {
        variables: {
            page: 1,
            id: params.locationId,
            ascending: filter.query.pagination.direction
        }
    });
    const locationItem = location.getLocationById;

    const { data: organisation = {} } = useQuery(ORGANIZATION, {
        variables: {
            page: 1,
            id: params.id,
            ascending: false
        }
    });
    const organisationItem = organisation.getOrganisationById;

    const { data: groups = {} } = useQuery(GROUPS, {
        variables: {
            page: 1,
            locationId: params.locationId
        }
    });
    const groupList = groups.getPaginatedGroupsByLocationId;

    useEffect(() => {
        if (locationItem) {
            const _rows = locationItem.formParticipants.items.map((participant) => {
                return [
                    {
                        label: participant.firstName + ' ' + participant.lastName || 'Leeg',
                        id: participant.id,
                        firstName: participant.firstName,
                        lastName: participant.lastName,
                        birthdate: format(new Date(participant.birthdate), 'MM-dd-yyyy') || 'Leeg',
                        email: participant.email
                    },
                    {
                        label: participant.results.length
                    },
                    {
                        label: participant.results.length
                            ? format(
                                  new Date(
                                      participant.results[participant.results.length - 1].date
                                  ),
                                  'dd-MM-yyyy'
                              )
                            : 'Geen'
                    }
                ];
            });

            setParticipants(_rows);
        }
    }, [locationItem]);

    useEffect(() => {
        if (groupList) {
            const _rows = groupList.items.map((group) => {
                return [
                    {
                        label: group.name || 'Leeg',
                        id: group.id
                    },
                    {
                        label: group.amountFormParticipants
                    }
                ];
            });

            setGroupRows(_rows);
        }
    }, [groupList]);

    const handleRowClick = (data) => {
        history(`/organisation/${params.id}/location/${params.locationId}/group/${data[0].id}`);
    };

    const handleMailClick = () => {
        setMailOpen(true);
    };

    const handleMailClose = () => {
        setMailOpen(false);
    };

    if (loading && !participants.length) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid
                title={organisationItem && organisationItem.name}
                location={locationItem && locationItem.name}
                onMailClick={!groupRows.length ? handleMailClick : ''}
            >
                {locationItem && locationItem.averageEnergizersPerResult.length ? (
                    <Summary
                        title={locationItem && locationItem.name}
                        rows={locationItem ? locationItem.averageEnergizersPerResult : []}
                    />
                ) : (
                    ''
                )}
                {groupRows.length ? (
                    <Table
                        layout="fixed"
                        head={tableHead}
                        rows={groupRows}
                        onClick={handleRowClick}
                    />
                ) : (
                    ''
                )}
                {!groupRows.length && (
                    <ParticipantTable
                        filter={filter}
                        onClose={handleMailClose}
                        mailOpen={mailOpen}
                        participants={participants}
                    />
                )}
            </DefaultGrid>
        </Layout>
    );
};

export default LocationOverview;
