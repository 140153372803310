import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SelectField } from 'packages/shared/core';
import Close from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    button: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '15px',

        '&:hover': {
            background: theme.palette.primary[900]
        }
    }
}));

//queries
const UserRole = (props) => {
    const { handleClose, form, onSubmit } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {t('COACHES.UPDATEROLE')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={handleClose}>
                    <Close />
                </IconButton>
            </div>
            <SelectField
                label={t('GENERAL.ROLE')}
                options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Coach', value: 'coach' }
                ]}
                name="role"
                form={form}
                type="role"
                value={form.fields.role || ''}
            />

            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={onSubmit}
            >
                {t('COACHES.UPDATEROLE')}
            </Button>
        </div>
    );
};

export default UserRole;
