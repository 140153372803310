import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import Table from 'packages/shared/Table';
import { ORGANISATIONS } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import { Drawer, Loader } from 'packages/shared/core';
import CreateOrganisation from './CreateOrganisation/CreateOrganisation';
import { Menu, MenuItem } from '@mui/material';

const OrganizationOverview = (props) => {
    const { t } = useTranslation();
    const {
        data = {},
        loading,
        refetch
    } = useQuery(ORGANISATIONS, {
        variables: {
            page: 1
        }
    });
    const history = useNavigate();

    // state hooks
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [currentOrganisation, setCurrentOrganisation] = useState('');

    const head = [t('GENERAL.ORGANISATION'), t('GENERAL.COACHROW'), t('GENERAL.USERS')];

    useEffect(() => {
        if (data && data.getPaginatedOrganisations) {
            const _rows = data.getPaginatedOrganisations.items.map((organisation) => {
                return [
                    {
                        label: organisation.name || 'Leeg',
                        id: organisation.id
                    },
                    {
                        label: organisation.coaches.slice(0, 3).map((coach, idx) => {
                            if (idx <= 1) {
                                return `${coach.firstName} ${coach.lastName} `;
                            } else {
                                return `+ ${organisation.coaches.length - 2} andere(n)`;
                            }
                        })
                    },
                    {
                        label: organisation.amountFormParticipants
                    }
                ];
            });

            setRows(_rows);
        }
    }, [data]);

    const handleAddClick = (event, data) => {
        setCurrentOrganisation(null);
        setOpen(true);
    };

    const handleRowClick = (data) => {
        history(`/organisation/${data[0].id}`);
    };

    const handleMenuClick = (event, r) => {
        setCurrentOrganisation(r[0]);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    const handleUpdateClick = (e) => {
        setOpen(true);
        setAnchorEl(false);
    };

    if (loading && !data.length) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid title={t('GENERAL.ORGANISATIONS')} addOrganization={handleAddClick}>
                <Table
                    head={head}
                    rows={rows}
                    onRowClick={handleRowClick}
                    options
                    onOptionsClick={handleMenuClick}
                />
            </DefaultGrid>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleUpdateClick}>{t('GENERAL.CHANGE')}</MenuItem>
            </Menu>
            <Drawer size="lg" position="absolute" open={open}>
                <CreateOrganisation
                    refetch={refetch}
                    currentOrganisation={currentOrganisation}
                    onClose={() => setOpen(false)}
                />
            </Drawer>
        </Layout>
    );
};

export default OrganizationOverview;
