import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const useStyles = makeStyles((theme) => ({
    breadcrumbItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 5px'
    },
    breadcrumbTitle: {
        marginBottom: '0',
        lineHeight: '31px'
    },
    icon: {
        color: theme.palette.tertiary[500]
    }
}));

const BreadcrumbItem = (props) => {
    const { title } = props;
    const classes = useStyles();

    return (
        <div className={classes.breadcrumbItem}>
            <ArrowRightIcon className={classes.icon} />
            <Typography
                variant="h1"
                component="h2"
                color="textSecondary"
                className={classes.breadcrumbTitle}
            >
                {title}
            </Typography>
        </div>
    );
};

export default BreadcrumbItem;
