import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
            background: theme.palette.primary[900]
        }
    }
}));

const SimpleDialog = (props) => {
    const {
        open = false,
        title,
        cancelButton,
        contentText,
        children,
        agreeButton,
        maxWidth = 'sm',
        noActions
    } = props;
    const classes = useStyles();

    const handleClose = () => {
        if (props.onClose) props.onClose();
    };

    const handleSubmit = () => {
        if (props.onSubmit) props.onSubmit();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth}>
            {title && <DialogTitle>{title || ''}</DialogTitle>}
            <DialogContent>
                {contentText && <DialogContentText>{contentText}</DialogContentText>}
                {children}
            </DialogContent>
            {!noActions && (
                <DialogActions>
                    {cancelButton && (
                        <Button onClick={handleClose} variant="outlined" color="primary">
                            {cancelButton}
                        </Button>
                    )}
                    <Button
                        className={classes.button}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        {agreeButton}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default SimpleDialog;
