import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField, DateField } from 'packages/shared/core';
import { useForm } from 'hooks';
import Close from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PARTICIPANT } from '../../graphql';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonCancel: {
        height: '40px'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    button: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '15px',

        '&:hover': {
            background: theme.palette.primary[900]
        }
    }
}));

//queries
const UpdateParticipant = (props) => {
    const { onClose, initial } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [form, updateParticipantForm] = useForm({
        firstName: {
            required: true
        },
        lastName: {
            required: true
        },
        email: {
            required: true,
            format: 'email'
        },
        birthdate: {
            required: false
        }
    });

    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
        refetchQueries: ['getSubgroupById']
    });

    const handleSubmit = () => {
        updateParticipantForm()
            .then((r) => {
                updateParticipant({
                    variables: {
                        updateFormParticipant: {
                            birthdate: r.birthdate
                                ? format(new Date(r.birthdate), 'yyyy-MM-dd')
                                : format(new Date(), 'yyyy-MM-dd'),
                            id: initial.id,
                            firstName: r.firstName,
                            lastName: r.lastName,
                            email: r.email
                        }
                    }
                }).then((resp) => {
                    if (!resp.errors) {
                        handleSuccess();
                    } else {
                        handleError();
                    }
                });
            })
            .catch((e) => handleError());
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('GENERAL.SUCCESSMESSAGE')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('GENERAL.DENYMESSAGE')
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {t('PARTICIPANT.CHANGE')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <TextField
                multiline
                fullWidth
                label={t('GENERAL.FIRSTNAME')}
                name="firstName"
                form={form}
                type="firstName"
                initialValue={initial.firstName}
            />
            <TextField
                multiline
                fullWidth
                label={t('GENERAL.LASTNAME')}
                name="lastName"
                form={form}
                initialValue={initial.lastName}
                type="lastName"
            />
            <TextField
                multiline
                fullWidth
                label={t('GENERAL.MAIL')}
                name="email"
                form={form}
                type="email"
                initialValue={initial.email}
            />
            <DateField
                multiline
                fullWidth
                label={t('GENERAL.BIRTHDATE')}
                name="birthdate"
                form={form}
                maxDate={new Date()}
                type="date"
                initialValue={initial.birthdate}
            />
            <div className={classes.buttonGroup}>
                <Button
                    className={classes.buttonCancel}
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                >
                    {t('GENERAL.DECLINE')}
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('PARTICIPANT.CHANGE')}
                </Button>
            </div>
        </div>
    );
};

export default UpdateParticipant;
