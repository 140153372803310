export const createErrorMessage = (schemaProps, key, t) => {
    if (key === 'requiredFields') {
        return 'Niet alle verplichte velden zijn gevuld';
    }

    if (key === 'required') {
        return t('validation.isRequired');
    }

    if (key === 'minValue') {
        if (schemaProps.minValueCustom) return schemaProps.minValueCustom;
        return t('validation.minValue');
    }

    if (key === 'maxLength') {
        return t('validation.maxLength') + ' ' + schemaProps[key];
    }

    if (key === 'minLength') {
        return t('validation.minLength') + ' ' + schemaProps[key];
    }

    if (key === 'format' && schemaProps[key] === 'email') {
        return t('validation.email');
    }

    if (key === 'format' && schemaProps[key] === 'password') {
        return t('validation.password');
    }

    if (key === 'format' && schemaProps[key] === 'phoneNumber') {
        return t('validation.phoneNumber');
    }

    if (key === 'format' && schemaProps[key] === 'date') {
        return t('validation.date');
    }

    if (key === 'couple') {
        return `${t('validation.couple')} ${schemaProps.coupleLabel}`;
    }

    if (key === 'type' && schemaProps[key] === 'number' && schemaProps.required) {
        return t('validation.number');
    }

    if (key === 'type' && schemaProps[key] === 'integer') {
        return t('validation.integer');
    }

    return t('validation.unknown');
};
