import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'hooks';
import Close from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { MAIL_PARTICIPANTS } from '../../graphql';
import { SelectField } from 'packages/shared/core';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    chipTitle: {
        marginBottom: '0',
        fontWeight: '600'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    button: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '15px'
    },
    chip: {
        margin: '1px',
        marginRight: '5px',
        marginTop: '10px',
        background: 'none',
        border: '1px solid #9E9E9E'
    }
}));

//queries
const MailParticipant = (props) => {
    const { onClose, initial } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState([]);

    const [form, updateParticipantForm] = useForm({
        type: {
            required: true
        }
    });

    const [mailParticipant] = useMutation(MAIL_PARTICIPANTS);

    useEffect(() => {
        if (initial) {
            setItems(initial);
        }
    }, [initial]);

    const handleSubmit = () => {
        updateParticipantForm()
            .then((r) => {
                mailParticipant({
                    variables: {
                        formParticipantIds: items.map((i) => i.id),
                        mailType: form.fields.type
                    }
                }).then((resp) => {
                    if (!resp.errors) {
                        handleSuccess();
                    } else {
                        handleError();
                    }
                });
            })
            .catch((e) => handleError());
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('PARTICIPANT.SUCCESSMAIL')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('PARTICIPANT.MAILDENIED')
        });
    };

    const handleDelete = (e) => () => {
        setItems((chipItem) => chipItem.filter((item) => item !== e));
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {t('GENERAL.SENDMAIL')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <div className={classes.row}>
                <Typography variant="body2" className={classes.chipTitle}>
                    Naar
                </Typography>

                <div className={classes.chips}>
                    {items.map((value, idx) => (
                        <Chip
                            key={idx}
                            label={value.firstName + ' ' + value.lastName}
                            onDelete={handleDelete(value)}
                            className={classes.chip}
                        />
                    ))}
                </div>
            </div>

            <SelectField
                label={t('PARTICIPANT.TYPEMAIL')}
                className={classes.select}
                options={[
                    { label: 'Mail - bij gesprek of training', value: 'follow-up' },
                    { label: 'Mail - PED via website', value: 'results' }
                ]}
                name="type"
                form={form}
            />
            <Button
                className={classes.button}
                disabled={items.length === 0}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                {t('GENERAL.MAILSEND')}
            </Button>
        </div>
    );
};

export default MailParticipant;
