import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { Typography, Card, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '15px',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '400px',
        minWidth: '344px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '16px'
    },
    typography: {
        fontWeight: 500
    },
    close: {},
    icons: {
        marginLeft: 'auto'
    },
    success: {
        backgroundColor: theme.palette.success[500],

        '& $typography': {
            color: theme.palette.primary.contrastText
        },
        '& $close': {
            color: theme.palette.primary.contrastText
        }
    },
    error: {
        backgroundColor: theme.palette.error[500],

        '& $typography': {
            color: theme.mind.white[500]
        },

        '& $close': {
            color: theme.mind.white[500]
        }
    },
    info: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    },
    warning: {
        backgroundColor: theme.palette.error.dark,

        '& $typography': {
            color: theme.palette.primary.contrastText
        },
        '& $close': {
            color: theme.palette.primary.contrastText
        }
    }
}));

const Snackbar = React.forwardRef((props, ref) => {
    const { message = '', variant = 'info' } = props;
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    return (
        <Card className={classNames(classes.root, classes[variant])} ref={ref}>
            <Typography variant="body1" className={classes.typography}>
                {message}
            </Typography>
            <div className={classes.icons}>
                <IconButton className={classes.expand} onClick={handleDismiss}>
                    <Close className={classes.close} />
                </IconButton>
            </div>
        </Card>
    );
});

Snackbar.propTypes = {
    id: PropTypes.number.isRequired
};

export default Snackbar;
