import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from 'packages/shared/Table';
import { Drawer, Loader } from 'packages/shared/core';
import { AddUser, DeactivateUser, UserRole } from './parts';
import { useSnackbar } from 'notistack';
import { ADD_USER, GET_USERS, UPDATE_USER } from './graphql';
import { useForm } from 'hooks';
import { useQuery, useMutation } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    rowWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '90px 0',
        flexWrap: 'wrap'
    },
    rowTitle: {
        display: 'flex',
        flex: '0 0 100%'
    }
}));

const UsersOverview = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [userRoleOpen, setUserRoleOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deactivateOpen, setDeactivateOpen] = useState(null);

    const [createUser] = useMutation(ADD_USER);
    const [updateUser] = useMutation(UPDATE_USER);

    const { enqueueSnackbar } = useSnackbar();
    const [addCoachForm, submitForm] = useForm({
        firstName: {
            required: true
        },
        lastName: {
            required: true
        },
        email: {
            required: true,
            format: 'email'
        },
        role: {
            required: true
        }
    });

    const [updateRoleForm, submitRoleForm] = useForm({
        role: {
            required: true
        }
    });

    // queries
    const { data = {}, loading, refetch } = useQuery(GET_USERS);

    const tableHead = [
        t('COACHES.COACH'),
        t('COACHES.ORGANIZATIONS'),
        t('COACHES.ROLE'),
        t('COACHES.STATUS')
    ];

    useEffect(() => {
        if (data && data.getUsers) {
            const _rows = data.getUsers.map((user) => {
                return [
                    {
                        label: user.firstName + ' ' + user.lastName || 'Leeg',
                        id: user.id,
                        active: user.active
                    },
                    {
                        label: user.amountOfOrganisations
                    },
                    {
                        label: user.role
                    },
                    {
                        label: user.active ? 'Actief' : 'Gedeactiveerd'
                    }
                ];
            });

            setRows(_rows);
        }
    }, [data]);

    const handleAddUserClick = () => {
        setAddUserOpen(true);
    };

    const handleUserRoleClick = () => {
        setUserRoleOpen(true);
    };

    const handleClose = () => {
        setAddUserOpen(false);
        setUserRoleOpen(false);
        setAnchorEl(null);
        setDeactivateOpen(false);
    };

    const handleSubmit = (event) => () => {
        switch (event) {
            case 'add':
                submitForm()
                    .then((r) => {
                        createUser({
                            variables: {
                                createUser: { ...r }
                            }
                        }).then((resp) => {
                            if (!resp.errors) {
                                handleSuccess();
                                refetch();
                                handleClose();
                            } else {
                                handleError();
                            }
                        });
                    })
                    .catch((e) => {
                        handleError();
                    });
                break;
            case 'update':
                submitRoleForm()
                    .then((r) => {
                        updateUser({
                            variables: {
                                updateUser: {
                                    ...r,
                                    id: currentUser.id
                                }
                            }
                        }).then((resp) => {
                            if (!resp.errors) {
                                handleSuccess();
                                refetch();
                                handleClose();
                            } else {
                                handleError();
                            }
                        });
                    })
                    .catch((e) => {
                        handleError();
                    });
                break;
            default:
                console.log('');
        }
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('GENERAL.DENYMESSAGE')
        });
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('GENERAL.SUCCESSMESSAGE')
        });
    };

    const handleMenuClick = (event, r) => {
        setAnchorEl(event.currentTarget);

        setCurrentUser(r[0]);
    };

    const handleOpenDeactivate = () => {
        setDeactivateOpen(true);
    };

    const handleDeactivate = (event) => {
        updateUser({
            variables: {
                updateUser: {
                    active: event.active,
                    id: currentUser.id
                }
            }
        })
            .then((r) => {
                if (!r.errors) {
                    handleSuccess();
                    refetch();
                } else {
                    handleError();
                }
            })
            .catch((e) => handleError());
    };

    if (loading && !data) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid title="Coaches" addCoach={handleAddUserClick}>
                <Table head={tableHead} rows={rows} onOptionsClick={handleMenuClick} />
                <Drawer position="absolute" className={classes.drawerWrapper} open={addUserOpen}>
                    <AddUser
                        handleClose={handleClose}
                        form={addCoachForm}
                        onSubmit={handleSubmit('add')}
                    />
                </Drawer>

                <Drawer position="absolute" className={classes.drawerWrapper} open={userRoleOpen}>
                    <UserRole
                        handleClose={handleClose}
                        form={updateRoleForm}
                        onSubmit={handleSubmit('update')}
                    />
                </Drawer>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ zIndex: '0' }}
                >
                    <MenuItem onClick={handleUserRoleClick}>{t('COACHES.UPDATEROLE')}</MenuItem>
                    <MenuItem onClick={handleOpenDeactivate}>
                        {t('COACHES.UPDATEDEACTIVATE')}
                    </MenuItem>
                </Menu>
                <DeactivateUser
                    initial={currentUser}
                    onSubmit={handleDeactivate}
                    open={Boolean(deactivateOpen)}
                    onClose={handleClose}
                />
            </DefaultGrid>
        </Layout>
    );
};

export default UsersOverview;
