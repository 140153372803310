import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    energy: {
        width: '50%',
        maxWidth: '675px'
    },

    energyScore: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginLeft: '-10px',

        '& span': {
            background: '#fff',
            padding: '5px 10px',
            display: 'block',
            position: 'relative',
            zIndex: '2'
        },

        '&::before': {
            background: 'rgba(38, 38, 38, 0.15)',
            height: '1px',
            width: '100%',
            content: "''",
            display: 'block',
            position: 'absolute',
            top: '50%'
        }
    },

    energyBar: {
        height: '100px',
        width: 'calc(100% - 20px)',
        marginTop: '25px',
        position: 'relative',
        overflow: 'hidden',
        zIndex: '0',
        marginBottom: '10px',

        '&::after': {
            background:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.4) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 0), #41ae48',
            backgroundSize: '30px auto',
            content: "''",
            height: '500px',
            width: '400px',
            display: 'block',
            position: 'absolute',
            top: '-230px',
            zIndex: '-1',
            transform: 'rotate(47deg)',
            right: '10px'
        }
    },

    activeBar: {
        height: '100px',
        background: '#1b93d1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '10px',
        color: '#fff',
        fontWeight: '600',
        fontSize: '30px',
        position: 'relative',

        '&::before': {
            content: "''",
            position: 'absolute',
            right: '-110px',
            width: '120px',
            height: '100px',
            zIndex: '0',
            background: 'linear-gradient(to right, white 0%, rgba(255, 255, 58, 0) 100%)'
        },

        '&::after': {
            content: "''",
            borderLeft: '50px solid transparent',
            borderRight: '50px solid transparent',
            borderBottom: '10px solid #1b93d1',
            transform: 'rotate(90deg)',
            right: '-45px',
            zIndex: '2',
            position: 'absolute'
        }
    },
    measurementTitle: {
        marginRight: '10px'
    }
}));

const EnergyLevel = (props) => {
    const { initial } = props;
    const classes = useStyles();

    return (
        <div className={classes.energy}>
            <div className={classes.energyScore}>
                <span>1</span>
                <span>50</span>
                <span>100</span>
            </div>
            {initial &&
                initial.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <div className={classes.energyBar}>
                            <div
                                style={{ width: item.total.toFixed(0) + '%' }}
                                className={classes.activeBar}
                            >
                                {item.total.toFixed(0) + '%'}
                            </div>
                        </div>
                        <Typography variant="body2">
                            <strong className={classes.measurementTitle}>{item.title}</strong>(
                            {format(new Date(item.date), 'dd-MM-yyyy')})
                        </Typography>
                    </React.Fragment>
                ))}
        </div>
    );
};

export default EnergyLevel;
