import { gql } from 'apollo-boost';

export const SUBGROUPS = gql`
    query getPaginatedSubgroupsByGroupId($groupId: Long!, $page: Int!) {
        getPaginatedSubgroupsByGroupId(groupId: $groupId, page: $page) {
            items {
                id
                name
                amountFormParticipants
                averageEnergizersPerResult {
                    averageEnergizerFeeding
                    averageEnergizerHome
                    averageEnergizerHumour
                    averageEnergizerMeaning
                    averageEnergizerMoving
                    averageEnergizerPassion
                    averageEnergizerRelaxing
                    averageEnergizerSleeping
                    averageEnergizerSocials
                    averageEnergizerWork
                }
            }
        }
    }
`;

export const ORGANIZATION = gql`
    query getOrganisationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getOrganisationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
        }
    }
`;

export const LOCATION = gql`
    query getLocationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getLocationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
        }
    }
`;

export const GROUP = gql`
    query getGroupById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getGroupById(id: $id, page: $page, ascending: $ascending) {
            name
            averageEnergizersPerResult {
                averageEnergizerFeeding
                averageEnergizerHome
                averageEnergizerHumour
                averageEnergizerMeaning
                averageEnergizerMoving
                averageEnergizerPassion
                averageEnergizerRelaxing
                averageEnergizerSleeping
                averageEnergizerSocials
                averageEnergizerWork
                averageTotal
            }
            formParticipants {
                items {
                    firstName
                    lastName
                    birthdate
                    id
                    email
                    results {
                        date
                    }
                }
            }
        }
    }
`;
