import React, { useReducer, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { TextField, MultiSelectField } from 'packages/shared/core';
import { useForm } from 'hooks';
import { IconButton, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import SubGroups from './SubGroups';
import { reducer } from './parts/reducer';
import { CREATE_ORGANISATION, ORGANISATION, COACHES, UPDATE_ORGANISATION } from '../graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    gridWrapColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    button: {
        marginLeft: 'auto',
        display: 'block',
        marginTop: '25px',

        '&:hover': {
            background: theme.palette.primary[900]
        }
    },
    textField: {
        width: '330px'
    }
}));

const mapGroup = (item) => {
    const obj = {
        formUrl: item.formUrl || null,
        name: item.name || null
    };

    if (!item.new && item.id) {
        obj.id = item.id;
    }

    return obj;
};

const buildLocations = (groups) => {
    return groups.map((i) => {
        return {
            ...mapGroup(i),
            groups:
                i.groups && i.groups.length
                    ? i.groups.map((g) => {
                          return {
                              ...mapGroup(g),
                              subgroups:
                                  g.subgroups && g.subgroups.length
                                      ? g.subgroups.map((sg) => {
                                            return {
                                                ...mapGroup(sg)
                                            };
                                        })
                                      : null
                          };
                      })
                    : null
        };
    });
};

const CreateOrganisation = (props) => {
    const { onClose, currentOrganisation, refetch } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [options, setOptions] = useState([]);
    const [coachesList, setCoachesList] = useState([]);

    const [form, submitForm] = useForm({
        name: {
            required: true
        }
    });
    const { t } = useTranslation();

    //mutations
    const [mutate, { data: mutationData, error }] = useMutation(CREATE_ORGANISATION);

    const [updateOrganisation, { data: updateData, error: updateError }] =
        useMutation(UPDATE_ORGANISATION);

    useEffect(() => {
        if (mutationData || updateData) {
            handleSuccess();
            onClose();
            refetch();
        }
        if (error || updateError) {
            handleError();
        }
        // eslint-disable-next-line
    }, [mutationData, error, updateError, updateData]);

    const { data: coaches = {} } = useQuery(COACHES);

    const allCoaches = coaches.getCoaches;

    //queries
    const [query, { data = {}, loading }] = useLazyQuery(ORGANISATION, {
        variables: {
            page: 1,
            ascending: false
        }
    });

    //reducers
    const [groups, setGroup] = useReducer(reducer, []);

    //effect hoos
    useEffect(() => {
        if (currentOrganisation && currentOrganisation.id) {
            query({
                variables: {
                    id: currentOrganisation.id
                }
            });
        }
    }, [currentOrganisation, query]);

    useEffect(() => {
        if (data && data.getOrganisationById && !loading) {
            setGroup({
                action: 'initial',
                data: data.getOrganisationById
            });
        }
    }, [data, loading]);

    //functions
    const handleSubmit = () => {
        submitForm().then((r) => {
            const build = buildLocations(groups);

            if (!build.some((i) => i.name)) {
                handleLocationError();

                return;
            }

            if (data.getOrganisationById) {
                updateOrganisation({
                    variables: {
                        updateOrganisation: {
                            ...r,
                            id: data.getOrganisationById.id,
                            locations: build
                        }
                    }
                });
            } else {
                mutate({
                    variables: {
                        createOrganisation: {
                            ...r,
                            locations: build
                        }
                    }
                });
            }
        });
    };

    const handleLocationError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: 'Naam is verplicht.'
        });
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('GENERAL.SUCCESSMESSAGE')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: 'Niet alle velden zijn ingevoerd.'
        });
    };

    useEffect(() => {
        if (coaches && allCoaches) {
            setOptions(
                allCoaches.map((coach) => {
                    return {
                        label: coach.firstName + ' ' + coach.lastName,
                        value: coach.id
                    };
                })
            );
        }
        // eslint-disable-next-line
    }, [coaches]);

    useEffect(() => {
        if (data.getOrganisationById) {
            setCoachesList(
                data.getOrganisationById.coaches.map((coach) => {
                    return coach.id;
                }, [])
            );
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {data.getOrganisationById
                        ? t('GENERAL.CHANGEORGANIZATION')
                        : t('GENERAL.ADDORGANIZATION')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.gridWrapColumn}>
                    <TextField
                        initialValue={data.getOrganisationById ? data.getOrganisationById.name : ''}
                        className={classes.textField}
                        label={t('GENERAL.NameOrganisation')}
                        name="name"
                        loading={loading}
                        form={form}
                    />

                    <MultiSelectField
                        initialValue={coachesList}
                        name="coachIds"
                        form={form}
                        label="Coach"
                        options={options}
                    />
                </Grid>
            </Grid>
            <Typography variant="body2">(Sub)groep(en)</Typography>
            <SubGroups groups={groups} initial={currentOrganisation} onUpdate={setGroup} />
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                {data.getOrganisationById
                    ? t('GENERAL.CHANGEORGANIZATION')
                    : t('GENERAL.ADDORGANIZATION')}
            </Button>
        </div>
    );
};

export default CreateOrganisation;
