import React from 'react';
import SimpleDialog from 'packages/shared/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '100vw',
        maxWidth: '280px',
        minWidth: '200px',
        color: theme.palette.tertiary[500],
        fontSize: '15px',
        lineHeight: '24px',
        fontFamily: theme.mind.bodyFont
    }
}));

const DeleteMeasurement = (props) => {
    const { open, onClose, initial, onSubmit } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const initialData = initial && initial[0];

    const handleClick = () => {
        onSubmit();
        onClose(true);
    };

    return (
        <SimpleDialog
            open={open}
            onClose={onClose}
            onSubmit={handleClick}
            cancelButton={t('GENERAL.DECLINE')}
            agreeButton={t('GENERAL.DELETEBUTTON')}
            className={classes.dialog}
        >
            <Typography variant="body2">
                {t('PARTICIPANT.DELETEPOPUP1')}

                {initialData ? format(new Date(initialData.date), 'dd-MM-yyyy') : ''}

                {t('PARTICIPANT.DELETEPOPUP2')}
            </Typography>
        </SimpleDialog>
    );
};

export default DeleteMeasurement;
