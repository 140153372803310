import { v4 as uuid } from 'uuid';

const getIndexOfId = (arr, id) => {
    let index;
    arr.forEach((i, idx) => {
        if (i.id === id) {
            index = idx;
        }
    });

    return index;
};

export const reducer = (state, action) => {
    const _state = [...state];
    const idx = getIndexOfId(state, action.id);
    const groupIdx = action.groupId ? getIndexOfId(_state[idx].groups, action.groupId) : null;
    const subgroupIdx = action.subgroupId
        ? getIndexOfId(_state[idx].groups[groupIdx].subgroups, action.subgroupId)
        : null;

    switch (action.action) {
        case 'add':
            return [
                ...state,
                {
                    id: uuid(),
                    new: true
                }
            ];
        case 'addGroup': {
            _state[idx].formUrl = null;
            _state[idx].groups
                ? _state[idx].groups.push({ id: uuid(), new: true })
                : (_state[idx].groups = [{ id: uuid(), new: true }]);

            return [..._state];
        }
        case 'addSubgroup': {
            _state[idx].groups[groupIdx].formUrl = null;
            _state[idx].groups[groupIdx].subgroups
                ? _state[idx].groups[groupIdx].subgroups.push({ id: uuid(), new: true })
                : (_state[idx].groups[groupIdx].subgroups = [{ id: uuid(), new: true }]);

            return [..._state];
        }
        case 'textfield': {
            if (subgroupIdx !== null)
                _state[idx].groups[groupIdx].subgroups[subgroupIdx].name = action.value;
            else if (groupIdx !== null) _state[idx].groups[groupIdx].name = action.value;
            else if (idx !== null) _state[idx].name = action.value;

            return [..._state];
        }
        case 'url': {
            if (subgroupIdx !== null)
                _state[idx].groups[groupIdx].subgroups[subgroupIdx].formUrl = action.value;
            else if (groupIdx !== null) _state[idx].groups[groupIdx].formUrl = action.value;
            else if (idx !== null) _state[idx].formUrl = action.value;

            return [..._state];
        }
        case 'initial': {
            return [...action.data.locations];
        }
        default:
            return state;
    }
};
