import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CloudDownload from '@mui/icons-material/CloudDownloadOutlined';
import { SearchField } from 'packages/shared/core';
import BreadcrumbItem from './BreadcrumbItem';
import SearchOverview from 'components/SearchOverview';
import { CURRENT_USER, PARTICIPANT_FILTER } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import MailIcon from '@mui/icons-material/MailOutlineOutlined';
import { useFilter } from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    titleWrap: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
        justifyContent: 'space-between',
        maxWidth: '1125px'
    },
    title: {
        margin: '0'
    },
    logoWrap: {
        backgroundColor: theme.palette.primary[50],
        width: '40px',
        height: '40px',
        padding: '5px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px'
    },
    location: {
        display: 'flex',
        margin: '0',
        alignItems: 'center',
        color: theme.palette.primary[200]
    },
    arrowRight: {
        color: theme.palette.primary[500],
        margin: '0 10px'
    },
    iconBG: {
        background: theme.mind.white[500],
        height: '40px',
        width: '40px'
    },
    icon: {
        color: theme.palette.tertiary[500]
    },
    button: {
        marginLeft: '30px',
        '&:hover': {
            background: theme.palette.primary[900]
        }
    },
    grid: {
        backgroundColor: theme.mind.white[500],
        borderRadius: '6px',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.05)',
        padding: '20px 20px 40px',
        maxWidth: '1125px'
    },
    PopupMenu: {
        marginTop: '50px'
    },
    buttonsWrap: {
        display: 'flex',
        alignItems: 'center'
    },
    breadCrumb: {
        display: 'flex',
        whiteSpace: 'pre',
        maxWidth: '90%',
        overflow: 'hidden'
    },
    breadcrumbTitle: {
        color: theme.palette.text.secondary[500],
        marginBottom: '0',
        opacity: '0.8'
    }
}));

const DefaultGrid = (props) => {
    const {
        children,
        title,
        addOrganization,
        onPdfClick,
        onMailClick,
        addCoach,
        location,
        group,
        subgroup,
        participant
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState('');
    const [search, setSearch] = useState('');
    const timer = useRef();

    const { data: user = {} } = useQuery(CURRENT_USER);

    const handleClick = (event, data) => {
        addOrganization();
    };

    const filter = useFilter({
        pagination: { sortProperties: ['state'], direction: 'ASC' }
    });

    const { data, loading } = useQuery(PARTICIPANT_FILTER, {
        variables: {
            query: search,
            page: filter.query.pagination.page
        }
    });

    const handleSearch = (event) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setSearch(event);
        }, 500);
    };

    const handleMailClick = () => {
        if (props.onMailClick) props.onMailClick(true);
    };

    const handleCoachClick = (event, data) => {
        props.addCoach(event, data);
    };

    useEffect(() => {
        if (user && user.getCurrentUser) {
            setCurrentUser(user.getCurrentUser);
        }
    }, [user]);

    return (
        <div className={classes.root}>
            <div className={classes.titleWrap}>
                <div className={classes.breadCrumb}>
                    <Typography variant="h1" paragraph className={classes.title}>
                        {search || title}
                    </Typography>

                    {!search ? (
                        <React.Fragment>
                            {location && <BreadcrumbItem title={location} />}
                            {group && <BreadcrumbItem title={group} />}
                            {subgroup && <BreadcrumbItem title={subgroup} />}
                            {participant && <BreadcrumbItem title={participant} />}
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                </div>
                <div className={classes.buttonsWrap}>
                    {onPdfClick && (
                        <React.Fragment>
                            <IconButton
                                className={classes.iconBG}
                                onClick={onPdfClick}
                                aria-label="delete"
                            >
                                <CloudDownload className={classes.icon} />
                            </IconButton>
                        </React.Fragment>
                    )}

                    {onMailClick && (
                        <IconButton
                            className={classes.iconBG}
                            onClick={handleMailClick}
                            aria-label="delete"
                        >
                            <MailIcon className={classes.icon} />
                        </IconButton>
                    )}
                    <SearchField name="search" query={filter} onChange={handleSearch} />
                    {addCoach && currentUser.role === 'admin' && (
                        <Button
                            className={classes.button}
                            onClick={handleCoachClick}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            {t('GENERAL.ADDCOACH')}
                        </Button>
                    )}
                    {addOrganization && currentUser.role === 'admin' && (
                        <Button
                            className={classes.button}
                            onClick={handleClick}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            {t('GENERAL.ADDORGANIZATION')}
                        </Button>
                    )}
                </div>
            </div>
            <div className={classes.grid}>
                {search ? (
                    <SearchOverview initial={data} filter={filter} loading={loading} />
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

export default DefaultGrid;
