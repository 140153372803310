import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'packages/shared/Table';
import { Loader } from 'packages/shared/core';
import { useNavigate } from 'react-router-dom';

const OrganizationOverview = (props) => {
    const { initial, loading, filter } = props;
    const { t } = useTranslation();
    const [searchResults, setSearchData] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        if (initial && initial.searchFormParticipants) {
            const _rows = initial.searchFormParticipants.items.map((search) => {
                return [
                    {
                        label: search.firstName + ' ' + search.lastName,
                        id: search.id
                    },
                    {
                        label: search.organisation && search.organisation.name,
                        id: search.organisation && search.organisation.id
                    },
                    {
                        label:
                            search.coaches &&
                            search.coaches.map((coach) => {
                                return coach.name + ' ';
                            }),
                        id:
                            search.coaches &&
                            search.coaches.map((coach) => {
                                return coach.id;
                            })
                    }
                ];
            });
            setSearchData(_rows);
        }
    }, [initial]);

    const handleRowClick = (id) => {
        if (id) {
            history(`/participant/${id[0].id}`);
        }
    };

    const head = [t('GENERAL.PARTICIPANT'), t('GENERAL.ORGANISATION'), t('GENERAL.COACH')];

    if (loading && !searchResults.length) return <Loader centered />;
    return <Table head={head} onRowClick={handleRowClick} filter={filter} rows={searchResults} />;
};

export default OrganizationOverview;
