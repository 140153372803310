import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import keycloak from 'network/keycloak';

const customFetch = (uri, options) => {
    const token = keycloak.token;
    return fetch(uri, {
        ...options,
        headers: {
            ...options.headers,
            authorization: token ? `Bearer ${token}` : '',
            'x-language': 'nl-NL' || 'default'
        }
    });
};
const httpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    batchInterval: 20,
    credentials: 'include',
    fetch: customFetch
});
const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch: customFetch
});
const errorLink = onError(({ response, operation, graphQLErrors, networkError }) => {
    if (networkError) {
        console.log('networkError', networkError);
    } else if (graphQLErrors) {
        console.log('graphQLErrors', graphQLErrors);
    }
});
const link = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    uploadLink,
    httpLink,
    errorLink
);
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});
export default client;
