import React from 'react';
import { SelectField } from 'packages/shared/core';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { GROUPS } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    select: {
        maxWidth: '33.3%',
        width: '100%',
        marginRight: '30px'
    }
}));

const GroupField = (props) => {
    const { form, id, onChange } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: groups = {} } = useQuery(GROUPS, {
        variables: {
            locationId: id
        }
    });
    const groupList = groups.getGroupsByLocationId;

    return (
        <React.Fragment>
            <SelectField
                label={t('PARTICIPANT.GROUP')}
                className={classes.select}
                emptyField
                options={
                    groupList &&
                    groupList.map((o) => {
                        return {
                            label: o.name,
                            value: o.id
                        };
                    })
                }
                name="group"
                form={form}
                onChange={onChange}
                readOnly={!!((groupList && groupList.length === 0) || groupList === undefined)}
            />
        </React.Fragment>
    );
};

export default GroupField;
