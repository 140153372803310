import React from 'react';
import SimpleDialog from 'packages/shared/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '100vw',
        maxWidth: '280px',
        minWidth: '200px',
        color: theme.palette.tertiary[500],
        fontSize: '15px',
        lineHeight: '24px',
        fontFamily: theme.mind.bodyFont
    }
}));

const DeactivateUser = (props) => {
    const { open, onClose, onSubmit, initial } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClick = () => {
        onSubmit({ active: !initial.active });
        onClose(true);
    };

    return (
        <SimpleDialog
            open={open}
            onClose={onClose}
            onSubmit={handleClick}
            cancelButton={t('GENERAL.DECLINE')}
            agreeButton={initial.active ? t('GENERAL.DEACTIVATE') : t('GENERAL.ACTIVATE')}
            className={classes.dialog}
        >
            <Typography variant="body2">
                {t('COACHES.DEACTIVATEFIRST')} {initial.label}{' '}
                {initial.active ? t('COACHES.DEACTIVATELAST') : t('COACHES.ACTIVATELAST')}
            </Typography>
        </SimpleDialog>
    );
};

export default DeactivateUser;
