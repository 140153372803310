import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OptionsIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import SummaryItem from './SummaryItem';

const useStyles = makeStyles((theme) => ({
    table: {
        marginBottom: '60px',
        display: 'grid',
        width: 'calc(100% - 50px)'
    },
    tableCellHead: {
        color: theme.palette.tertiary[500],
        fontSize: '15px',
        fontFamily: theme.mind.titleFont,
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        textAlign: 'center',
        background: 'rgba(38,38,38,0.1)',
        width: '100%',
        hyphens: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '500',

        '&:first-of-type': {
            justifyContent: 'flex-start',
            paddingLeft: '20px'
        },

        '&:last-of-type': {
            background: theme.palette.tertiary[500],
            color: theme.mind.white[500]
        }
    },
    tableItemCell: {
        textAlign: 'center',
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        background: 'rgba(38,38,38,0.1)',
        minHeight: '50px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        fontSize: '15px',

        '&:last-of-type': {
            background: theme.palette.tertiary[500],
            color: theme.mind.white[500]
        }
    },
    options: {
        background: '#FFF',
        border: 'none',
        padding: '0',
        maxWidth: '50px',
        width: '100%',
        display: 'flex',
        height: '50px',
        position: 'absolute',
        right: '0'
    },
    row: {
        display: 'grid',
        minHeight: '50px',
        //design bedankt
        gridTemplateColumns:
            'minmax(150px, 1fr) minmax(70px, 1fr) minmax(80px, 1fr) minmax(85px, 1fr) minmax(70px, 1fr) minmax(65px, 1fr) minmax(115px, 1fr) minmax(90px, 1fr) minmax(60px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) minmax(65px, 1fr) minmax(50px, 60px)',
        position: 'relative'
    },
    headerRow: {
        display: 'grid',
        minHeight: '50px',
        //design bedankt
        gridTemplateColumns:
            'minmax(150px, 1fr) minmax(70px, 1fr) minmax(80px, 1fr) minmax(85px, 1fr) minmax(70px, 1fr) minmax(65px, 1fr) minmax(115px, 1fr) minmax(90px, 1fr) minmax(60px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) minmax(65px, 1fr) minmax(50px, 60px)'
    }
}));

const SummaryComponent = (props) => {
    const { rows, title, onOptionsClick, headerTitle, customTitle } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const head = [
        headerTitle || '',
        t('SUMMARY.SLEEP'),
        t('SUMMARY.NUTRITION'),
        t('SUMMARY.MOVING'),
        t('SUMMARY.HUMOR'),
        t('SUMMARY.PASSION'),
        t('SUMMARY.RELAX'),
        t('SUMMARY.CONTACT'),
        t('SUMMARY.HOME'),
        t('SUMMARY.WORK'),
        t('SUMMARY.MEANING'),
        t('SUMMARY.TOTAL')
    ];

    return (
        <React.Fragment>
            <Typography variant="h5" component="h2">
                {customTitle || t('GENERAL.SUMMARYOF')} {title}
            </Typography>
            <div className={classes.table}>
                <div className={classes.headerRow}>
                    {head &&
                        head.map((el, idx) => (
                            <div align="left" key={idx} className={classes.tableCellHead}>
                                {el.label || el}
                            </div>
                        ))}
                </div>
                {rows &&
                    rows.length > 0 &&
                    rows.map((row, idx) => (
                        <div key={idx} className={classes.row}>
                            <div className={classes.tableItemCell}>
                                {row.date
                                    ? idx + 1 + ')  ' + format(new Date(row.date), 'dd-MM-yyyy')
                                    : idx + 1 + 'e meting'}
                            </div>
                            <SummaryItem
                                result={row.energizerSleeping || row.averageEnergizerSleeping}
                            />
                            <SummaryItem
                                result={row.energizerFeeding || row.averageEnergizerFeeding}
                            />
                            <SummaryItem
                                result={row.energizerMoving || row.averageEnergizerMoving}
                            />
                            <SummaryItem
                                result={row.energizerHumour || row.averageEnergizerHumour}
                            />
                            <SummaryItem
                                result={row.energizerPassion || row.averageEnergizerPassion}
                            />
                            <SummaryItem
                                result={row.energizerRelaxing || row.averageEnergizerRelaxing}
                            />
                            <SummaryItem
                                result={row.energizerSocials || row.averageEnergizerSocials}
                            />
                            <SummaryItem result={row.energizerHome || row.averageEnergizerHome} />
                            <SummaryItem result={row.energizerWork || row.averageEnergizerWork} />
                            <SummaryItem
                                result={row.energizerMeaning || row.averageEnergizerMeaning}
                            />
                            <SummaryItem
                                total={
                                    (row.total && row.total.toFixed(1) + '%') ||
                                    (row.averageTotal && row.averageTotal.toFixed(1) + '%')
                                }
                            />
                            {onOptionsClick && (
                                <div className={classes.options}>
                                    <IconButton
                                        className={classes.menuButton}
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => onOptionsClick(event, row.id)}
                                    >
                                        <OptionsIcon />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
};

export default SummaryComponent;
