import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OrganizationOverview from 'components/OrganizationOverview';
import LocationOverview from 'components/LocationOverview';
import GroupOverview from 'components/GroupOverview';
import Participant from 'components/Participant';
import UsersOverview from 'components/UsersOverview';
import SubgroupOverview from 'components/SubgroupOverview';
import ParticipantOverview from 'components/ParticipantOverview';

const Router = (props) => {
    return (
        <Routes>
            <Route exact path="/" element={<OrganizationOverview />} />
            <Route exact path="/organisation/:id" element={<LocationOverview />} />
            <Route
                exact
                path="/organisation/:id/location/:locationId"
                element={<GroupOverview />}
            />
            <Route
                exact
                path="/organisation/:id/location/:locationId/group/:groupId"
                element={<SubgroupOverview />}
            />
            <Route
                exact
                path="/organisation/:id/location/:locationId/group/:groupId/subgroup/:subgroupId"
                element={<ParticipantOverview />}
            />
            <Route exact path="/participant/:participantId" element={<Participant />} />
            <Route exact path="/coaches" element={<UsersOverview />} />
        </Routes>
    );
};

export default Router;
