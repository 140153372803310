import React, { useState, useRef, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Chart as ChartJS, registerables } from 'chart.js';

const useStyles = makeStyles((theme) => ({
    rowTitle: {
        display: 'flex',
        flex: '0 0 100%'
    },
    legend: {
        minWidth: '240px',
        border: '1px solid rgba(38, 38, 38, 0.2)',
        maxHeight: '500px',
        padding: '40px 150px 40px 40px'
    },
    legendWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px'
    },
    legendItem: {
        marginBottom: '40px'
    },
    legendHidden: {
        display: 'none'
    },
    legendLine0: {
        height: '3px',
        width: '100%',
        background: '#262626',
        margin: '10px 0'
    },
    legendLine1: {
        backgroundImage:
            'repeating-linear-gradient(-3deg, #262626, #262626 26px, transparent 26px, transparent 35px, #262626 35px), repeating-linear-gradient(87deg, #262626, #262626 26px, transparent 26px, transparent 35px, #262626 35px), repeating-linear-gradient(177deg, #262626, #262626 26px, transparent 26px, transparent 35px, #262626 35px), repeating-linear-gradient(267deg, #262626, #262626 26px, transparent 26px, transparent 35px, #262626 35px)',
        backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
        height: '2px',
        margin: '10px 0'
    },
    legendLine2: {
        backgroundImage:
            'repeating-linear-gradient(-1deg, #262626, #262626 3px, transparent 3px, transparent 8px, #262626 8px), repeating-linear-gradient(89deg, #262626, #262626 3px, transparent 3px, transparent 8px, #262626 8px), repeating-linear-gradient(179deg, #262626, #262626 3px, transparent 3px, transparent 8px, #262626 8px), repeating-linear-gradient(269deg, #262626, #262626 3px, transparent 3px, transparent 8px, #262626 8px)',
        backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
        height: '2px',
        margin: '10px 0'
    },
    legendLine3: {
        height: '2px',
        width: '100%',
        background: '#262626',
        margin: '10px 0'
    },
    legendLine4: {
        backgroundImage:
            'repeating-linear-gradient(-1deg, #262626, #262626 6px, transparent 6px, transparent 12px, #262626 12px), repeating-linear-gradient(89deg, #262626, #262626 6px, transparent 6px, transparent 12px, #262626 12px), repeating-linear-gradient(179deg, #262626, #262626 6px, transparent 6px, transparent 12px, #262626 12px), repeating-linear-gradient(269deg, #262626, #262626 6px, transparent 6px, transparent 12px, #262626 12px)',
        backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
        height: '2px',
        margin: '10px 0'
    }
}));

const Chart = (props) => {
    const { initial } = props;
    const inputEl = useRef(null);
    const [legendRendered, setLegendRendered] = useState(false);
    const [, forceUpdate] = useState();
    const classes = useStyles();
    const [resultRows, setResultRows] = useState(null);
    const [resultDate, setResultDate] = useState(null);

    ChartJS.register(...registerables);

    useEffect(() => {
        if (initial && initial.results) {
            const _rows = initial.results.map((item, idx) => {
                return [
                    item.energizerSleeping.toFixed(1) * 10 || '0',
                    item.energizerFeeding.toFixed(1) * 10 || '0',
                    item.energizerMoving.toFixed(1) * 10 || '0',
                    item.energizerHumour.toFixed(1) * 10 || '0',
                    item.energizerPassion.toFixed(1) * 10 || '0',
                    item.energizerRelaxing.toFixed(1) * 10 || '0',
                    item.energizerSocials.toFixed(1) * 10 || '0',
                    item.energizerHome.toFixed(1) * 10 || '0',
                    item.energizerWork.toFixed(1) * 10 || '0',
                    item.energizerMeaning.toFixed(1) * 10 || '0'
                ];
            });

            setResultRows(_rows);
        }
    }, [initial]);

    useEffect(() => {
        if (initial && initial.results) {
            const _date = initial.results.map((item, idx) => {
                return format(new Date(item.date), 'dd-MM-yyyy');
            });

            setResultDate(_date);
        }
    }, [initial]);

    const chartOptions = {
        responsive: false,
        scale: {
            gridLines: {
                lineWidth: [1, 1, 1, 1, 2, 1, 2, 1, 1, 3],
                color: [
                    'rgba(219,40,24,0.5)',
                    'rgba(219,40,24,0.5)',
                    'rgba(219,40,24,0.5)',
                    'rgba(219,40,24,0.5)',
                    '#DB2818',
                    'rgba(237,109,35,0.5)',
                    '#ED6D23',
                    'rgba(56,170,64,0.5)',
                    'rgba(56,170,64,0.5)',
                    '#38AA40'
                ]
            },
            ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
                stepSize: 10,
                fontSize: 0,
                showLabelBackdrop: false
            },
            angleLines: {
                display: false
            },
            pointLabels: {
                fontSize: 15,
                defaultFontFamily: 'Open Sans',
                fontColor: '#262626'
            }
        },
        tooltips: {
            enabled: false
        },
        legend: {
            display: false
        },
        legendLine0: {
            background: 'green'
        }
    };

    const data = {
        labels: [
            'Slapen',
            'Voeding',
            'Bewegen',
            'Humor',
            'Passie',
            'Ontspanning',
            'Sociale contacten',
            'Thuis',
            'Werkleven',
            'Zingeving'
        ],
        datasets: [
            {
                label: '1e meting',
                borderColor: '#262626',
                fill: false,
                borderWidth: 3,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointRadius: 0,
                data: resultRows && resultRows[0]
            },
            {
                label: '2e meting',
                backgroundColor: 'transparent',
                borderColor: '#262626',
                fill: false,
                borderWidth: 2,
                borderDash: [25, 10],
                borderDashOffset: 1,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointRadius: 0,
                data: resultRows && resultRows[1]
            },
            {
                label: '3e meting',
                backgroundColor: 'transparent',
                borderColor: '#262626',
                fill: false,
                borderWidth: 2,
                borderDash: [3, 3],
                borderDashOffset: 1,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointRadius: 0,
                data: resultRows && resultRows[2]
            },
            {
                label: '4e meting',
                backgroundColor: 'transparent',
                borderColor: '#262626',
                fill: false,
                borderWidth: 2,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointRadius: 0,
                data: resultRows && resultRows[3]
            },
            {
                label: '5e meting',
                backgroundColor: 'transparent',
                borderColor: '#262626',
                fill: false,
                borderWidth: 2,
                borderDash: [6, 5],
                borderDashOffset: 10,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointRadius: 0,
                data: resultRows && resultRows[4]
            }
        ]
    };

    const handleLegendClick = (datasetIndex) => {
        const chart = inputEl.current.chartInstance;
        chart.getDatasetMeta(datasetIndex).hidden =
            chart.getDatasetMeta(datasetIndex).hidden === null
                ? true
                : !chart.getDatasetMeta(datasetIndex).hidden;
        chart.update(); // re-draw chart to hide dataset
        forceUpdate({}); // re-draw component to update legend styles
    };

    const plugins = [
        {
            afterDatasetsUpdate() {
                // hack to force re-render component in order to show legend
                if (!legendRendered) {
                    setLegendRendered(true);
                }
            }
        }
    ];

    return (
        <React.Fragment>
            <div>
                <Radar
                    ref={inputEl}
                    plugins={plugins}
                    options={chartOptions}
                    width={600}
                    height={500}
                    data={data}
                />
            </div>
            {legendRendered && (
                <div className={classes.legend}>
                    <div className={classes.legend__wrapper}>
                        <Typography variant="subtitle1">Legenda</Typography>

                        {inputEl.current.legend.legendItems.map((tick, idx) => {
                            return (
                                <div
                                    onClick={() => handleLegendClick(tick.datasetIndex)}
                                    key={tick.datasetIndex}
                                >
                                    {resultDate && resultDate[idx] && (
                                        <div className={classNames(classes.legendItem)}>
                                            <Typography>
                                                {tick.text} {resultDate && resultDate[idx]}
                                            </Typography>
                                            <div className={classes[`legendLine${idx}`]} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
export default Chart;
