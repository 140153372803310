import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import keycloak from 'network/keycloak';
import { useLocation } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Logo from 'assets/logo.svg';
import { CURRENT_USER } from './graphql';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.mind.defaultBackgroundColor[500],
        display: 'flex',
        flexDirection: 'column',
        padding: '30px',
        justifyContent: 'space-between'
    },
    logo: {
        maxWidth: '100%',
        margin: '0 0 70px',
        height: '30px',

        '& img': {
            maxWidth: '160px'
        }
    },
    userLink: {
        textTransform: 'capitalize',
        fontSize: '16px',
        lineHeight: '28px',
        color: 'rgba(255, 255, 255, 0.5)',
        fontWeight: '500',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0',
        alignItems: 'center'
    },
    initials: {
        width: '40px',
        height: '40px',
        background: 'rgba(38, 38, 38, 0.15)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'center',
        justifyContent: 'center'
    },
    initialsText: {
        display: 'flex',
        alignItems: 'center',
        color: theme.mind.white[500],
        letterSpacing: '1px',
        fontSize: '20px',
        fontFamily: theme.mind.titleFont,
        fontWeight: '400'
    },
    userName: {
        color: theme.mind.white[500],
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: theme.mind.bodyFont
    },
    role: {
        color: theme.mind.white[500],
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '600'
    },
    navItem: {
        marginBottom: '10px',
        fontFamily: theme.mind.titleFont,
        fontSize: '20px',

        '&:hover': {
            color: theme.mind.white[500]
        }
    },
    logout: {
        cursor: 'pointer',
        color: theme.mind.white[500],

        '&:hover': {
            color: theme.palette.tertiary[500]
        }
    },
    contentWrap: {
        display: 'flex'
    },
    nameWrap: {
        marginLeft: '15px'
    }
}));

const SidebarGrid = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const { data = {} } = useQuery(CURRENT_USER);

    const currentUser = data.getCurrentUser || {};

    const handleLogout = () => {
        keycloak.logout({ redirectUri: `${process.env.REACT_APP_MIND_DOMAIN}/` });
    };

    return (
        <div className={classes.root}>
            <div>
                <figure className={classes.logo}>
                    <img src={Logo} alt="leeg" />
                </figure>
                <Link
                    to="/"
                    className={classes.navItem}
                    active={
                        location.pathname === '/' || location.pathname.startsWith('/organisation')
                    }
                >
                    {t('GENERAL.ORGANISATIONS')}
                </Link>
                {currentUser.role === 'admin' && (
                    <Link
                        to="/coaches"
                        className={classes.navItem}
                        active={location.pathname.startsWith('/coaches')}
                    >
                        {t('GENERAL.COACHES')}
                    </Link>
                )}
            </div>
            <div className={classes.userLink}>
                <div className={classes.contentWrap}>
                    <div className={classes.initials}>
                        <div className={classes.initialsText}>
                            {keycloak && keycloak.idTokenParsed && (
                                <React.Fragment>
                                    {keycloak.idTokenParsed.given_name.slice(0, 1)}
                                    {keycloak.idTokenParsed.family_name.slice(0, 1)}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className={classes.nameWrap}>
                        <div className={classes.userName}>
                            {keycloak && keycloak.idTokenParsed && (
                                <React.Fragment>{keycloak.idTokenParsed.name}</React.Fragment>
                            )}
                        </div>
                        <div className={classes.role}>{currentUser.role}</div>
                    </div>
                </div>
                <ExitToAppIcon className={classes.logout} onClick={handleLogout} />
            </div>
        </div>
    );
};

export default SidebarGrid;
