import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    title: {
        fontWeight: 'bold'
    },
    label: {
        fontSize: '16px',
        position: 'relative'
    },
    select: {
        padding: '1px',
        height: '56px',
        fontSize: '16px',
        maxWidth: '328px',
        width: '100%'
    },
    loading: {
        position: 'absolute',
        zIndex: '99',
        width: '100%',
        height: '100%',
        background: 'white',
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity .2s'
    },
    activeLoading: {
        pointerEvents: 'visible',
        opacity: 1
    },
    chipsWrapper: {
        marginLeft: '20px'
    },
    chips: {
        whiteSpace: 'normal',
        marginTop: '5px'
    },
    chip: {
        margin: '1px',
        marginRight: '5px',
        background: 'none',
        border: '1px solid #9E9E9E'
    },
    labelItem: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const MultiSelectField = (props) => {
    const {
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        onChange,
        options = [],
        label,
        className,
        name,
        form = false,
        hint,
        initialValue = [],
        readOnly = false
    } = props;
    const classes = useStyles();

    //state hooks
    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [option, setOption] = useState([]);

    //effect hooks
    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form.errors, name]);

    useEffect(() => {
        if (initialValue) {
            setOption(initialValue);
            if (onChange) onChange(initialValue);
            if (form) {
                form.onFieldChange({ key: name, value: initialValue });
            }
        }
        // eslint-disable-next-line
    }, [initialValue]);

    //functions
    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.value });
        setOption(event.target.value);
        if (onChange) onChange(event.target.value);
    };

    const arrayToObject = (arr) => {
        const obj = {};

        arr.forEach((item) => {
            obj[item.value] = item.label;
        });

        return obj;
    };

    const optionsObj = arrayToObject(options);

    const handleDelete = (e) => () => {
        setOption((chipItem) => {
            const opts = chipItem.filter((item) => item !== e);
            if (form) form.onFieldChange({ key: name, value: opts });
            if (onChange) onChange(opts);
            return opts;
        });
    };

    return (
        <FormControl
            variant="outlined"
            className={classNames(classes.root, className)}
            error={error}
            margin="normal"
        >
            <InputLabel id={`label-${name}`}>
                <span className={classes.label}>{label}</span>
            </InputLabel>
            <Select
                className={classes.select}
                value={option}
                onChange={handleChange}
                labelId={`label-${name}`}
                id={`select-${name}`}
                multiple
                label={label}
                readOnly={readOnly}
                disabled={readOnly}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {option.map((value) => (
                            <span key={value}>{optionsObj[value]} </span>
                        ))}
                    </div>
                )}
            >
                {options.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
                        <span className={classes.labelItem}>
                            <Checkbox checked={option.indexOf(opt.value) > -1} />
                            {opt.label}
                        </span>
                    </MenuItem>
                ))}
            </Select>

            <div className={classes.chipsWrapper}>
                <Typography className={classes.title} variant="body2">
                    Toegevoegde coach(es)
                </Typography>
                <div className={classes.chips}>
                    {option.map((value) => (
                        <Chip
                            key={value}
                            label={optionsObj[value]}
                            onDelete={handleDelete(value)}
                            className={classes.chip}
                        />
                    ))}
                </div>
            </div>

            {error && <FormHelperText>{error && errorMessage}</FormHelperText>}
            {hint && <FormHelperText>{hint}</FormHelperText>}
        </FormControl>
    );
};

export default MultiSelectField;
