import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

let timer;
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    input: {
        backgroundColor: 'rgba(243,242,238,0.10)',
        border: 0,
        height: '46px',
        fontSize: '14px',
        padding: '0 50px 0 16px',
        borderRadius: '4px',
        fontFamily: theme.palette.defaultContentFont,
        opacity: 0,
        width: 0,
        pointerEvents: 'none',
        transition: 'opacity .2s',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.70)'
        }
    },
    iconBG: {
        background: theme.mind.white[500],
        height: '40px',
        width: '40px'
    },
    active: {
        opacity: 1,
        pointerEvents: 'visible',
        width: '278px',
        background: '#FFF',
        border: '1px solid rgba(0,0,0,0.38)'
    },
    icon: {
        position: 'absolute',
        right: '5px',
        top: '4px',
        cursor: 'pointer',
        transition: 'color .2s',
        color: theme.palette.tertiary[500],
        background: '#FFF',
        width: '40px',
        height: '40px',
        padding: '8px',
        borderRadius: '20px'
    },
    activeIcon: {
        color: theme.palette.tertiary[500]
    }
}));

const SearchField = (props) => {
    const { query = false, name = 'search' } = props;
    const inputEl = useRef(null);
    const classes = useStyles();
    const { t } = useTranslation();

    //state hooke
    const [active, setActive] = useState(false);
    const [value, setValue] = useState('');

    const handleClick = () => {
        const isActive = !active;
        setActive(isActive);
        if (isActive) {
            inputEl.current.focus();
        } else {
            inputEl.current.blur();
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setValue(value);
        if (props.onChange) props.onChange(value);
        if (query) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                query.setQuery({ filter: { [name]: value } });
            }, 500);
        }
    };

    useEffect(() => {
        if (query && query.filters) {
            const { filters = {} } = query;
            if (filters[name] && filters[name] !== value) {
                setActive(true);
                setValue(filters[name]);
            }
        }
        // eslint-disable-next-line
    }, [query.filters]);

    return (
        <div className={classes.root}>
            <input
                value={value}
                ref={inputEl}
                onChange={handleChange}
                className={classNames(classes.input, {
                    [classes.active]: active
                })}
                placeholder={t('GENERAL.search')}
            />
            <SearchIcon
                className={classNames(classes.icon, {
                    [classes.activeIcon]: active
                })}
                onClick={handleClick}
            />
        </div>
    );
};

export default SearchField;
