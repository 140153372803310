import React from 'react';
import { SelectField } from 'packages/shared/core';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { SUBGROUPS } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    select: {
        maxWidth: 'calc(33.3% - 30px)',
        width: '100%'
    }
}));

const SubgroupField = (props) => {
    const { form, id, onChange } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    //queries
    const { data = {} } = useQuery(SUBGROUPS, {
        variables: {
            groupId: id
        }
    });
    const subGroupList = data.getSubgroupsByGroupId;

    return (
        <React.Fragment>
            <SelectField
                label={t('PARTICIPANT.SUBGROUP')}
                className={classes.select}
                emptyField
                options={
                    subGroupList &&
                    subGroupList.map((o) => {
                        return {
                            label: o.name,
                            value: o.id
                        };
                    })
                }
                name="subgroup"
                form={form}
                onChange={onChange}
                readOnly={
                    !!((subGroupList && subGroupList.length === 0) || subGroupList === undefined)
                }
            />
        </React.Fragment>
    );
};

export default SubgroupField;
