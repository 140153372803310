import { gql } from 'apollo-boost';

export const PARTICIPANT = gql`
    query getFormParticipantById($id: Long!) {
        getFormParticipantById(id: $id) {
            firstName
            lastName
            id
            birthdate
            organisation {
                id
                name
            }
            location {
                name
            }
            group {
                name
            }
            subgroup {
                name
            }
            email
            results {
                date
                id
                energizerFeeding
                energizerHome
                energizerHumour
                energizerMeaning
                energizerMoving
                energizerPassion
                energizerRelaxing
                energizerSleeping
                energizerSocials
                energizerWork
                openQuestion
                openQuestionAnswer
                total
            }
        }
    }
`;

export const REMOVE_RESULT = gql`
    mutation removeResult($id: Long!) {
        removeResult(id: $id)
    }
`;

export const UPDATE_RESULT = gql`
    mutation updateResult($updateResult: UpdateResultInput!) {
        updateResult(updateResult: $updateResult) {
            date
            energizerFeeding
            energizerHome
            energizerHumour
            energizerMeaning
            energizerMoving
            energizerPassion
            energizerRelaxing
            energizerSleeping
            energizerSocials
            energizerWork
            id
        }
    }
`;
