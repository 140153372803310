import { compose, curry, Failure, Success } from './lib';
import {
    checkRequired,
    checkMaxLength,
    checkMinLength,
    checkType,
    checkFormat,
    checkCouple,
    checkMinValue,
    checkRequiredFields
} from './checks';

export const booleanToValidation = curry((failureString, valid) =>
    valid ? Success(true) : Failure([failureString])
);
export const validateRequired = compose(booleanToValidation('required'), checkRequired);
export const validateMaxLength = compose(booleanToValidation('maxLength'), checkMaxLength);
export const validateMinLength = compose(booleanToValidation('minLength'), checkMinLength);
export const validateType = compose(booleanToValidation('type'), checkType);
export const validateFormat = compose(booleanToValidation('format'), checkFormat);
export const validateCouple = compose(booleanToValidation('couple'), checkCouple);
export const validateMinValue = compose(booleanToValidation('minValue'), checkMinValue);
export const validateRequiredFields = compose(
    booleanToValidation('requiredFields'),
    checkRequiredFields
);

export const validateProperty = (schema, propertyValue, entity) => {
    const validators = [
        validateRequired,
        validateMaxLength,
        validateMinLength,
        validateType,
        validateFormat,
        validateCouple,
        validateMinValue,
        validateRequiredFields
    ];
    return validators.reduce(
        (validationResult, validator) =>
            validationResult.concat(validator(schema, propertyValue, entity)),
        Success(true)
    );
};

export const validateEntity = (schema, entity) => {
    return Object.keys(schema).reduce((validationResult, propertyName) => {
        return validationResult.concat(
            validateProperty(schema[propertyName], entity[propertyName], entity).mapFailure((v) => [
                {
                    propertyName: propertyName,
                    errors: v
                }
            ])
        );
    }, Success(true));
};
