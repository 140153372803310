import { gql } from 'apollo-boost';

export const ORGANISATIONS_FILTER = gql`
    query getPaginatedOrganisations($page: Int!, $query: String) {
        getPaginatedOrganisations(page: $page, query: $query) {
            amountOfPages
            page
            items {
                coaches {
                    firstName
                    lastName
                }
                name
                formParticipants {
                    firstName
                    lastName
                    id
                }
            }
        }
    }
`;

export const PARTICIPANT_FILTER = gql`
    query searchFormParticipants($query: String!, $page: Int!) {
        searchFormParticipants(query: $query, page: $page) {
            items {
                firstName
                lastName
                id
                organisation {
                    id
                    name
                }
                coaches {
                    id
                    name
                }
            }
        }
    }
`;

export const CURRENT_USER = gql`
    query getCurrentUser {
        getCurrentUser {
            role
            id
            firstName
            lastName
        }
    }
`;
