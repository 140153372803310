import React from 'react';
import App from 'components/App';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import keycloak from 'network/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { Snackbar } from 'packages/shared/core';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'client';

const Root = (props) => {
    const { theme } = props;
    const apiRegex = /^\/api\/.*/;

    if (apiRegex.test(window.location.pathname)) {
        return <div />;
    }
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            isLoadingCheck={(keycloak) => !keycloak.authenticated}
            initOptions={{
                onLoad: 'login-required'
            }}
            LoadingComponent={<React.Fragment />}
        >
            <ApolloProvider client={client}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider
                            autoHideDuration={5000}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            content={(key, object) => (
                                <Snackbar
                                    id={key}
                                    message={object.message}
                                    variant={object.variant}
                                />
                            )}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Router>
                                    <App />
                                </Router>
                            </LocalizationProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </ApolloProvider>
        </ReactKeycloakProvider>
    );
};

export default Root;
