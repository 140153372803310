import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OptionsIcon from '@mui/icons-material/MoreHoriz';
import classNames from 'classnames';
import Pagination from 'packages/shared/Pagination';

const useStyles = makeStyles((theme) => ({
    mediumSize: {
        maxWidth: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    smallSize: {
        padding: '12px 16px'
    },
    optionsIcon: {
        cursor: 'pointer',
        transition: 'color .3s',
        '&:hover': {
            color: theme.palette.secondary[500]
        }
    },

    fixedLayout: {
        tableLayout: 'fixed'
    },
    tableRow: {
        cursor: 'pointer',
        transition: 'background, .3s',
        '&:hover': {
            background: theme.palette.secondary[50]
        }
    },
    active: {
        background: theme.palette.secondary[50]
    },
    strong: {
        fontFamily: theme.mind.defaultTitleFont,
        fontWeight: 600,
        color: theme.palette.primary[500]
    },
    noBorder: {
        borderBottom: 0
    },
    empty: {
        padding: '20px'
    },
    tableCellHead: {
        textAlign: 'left!important',
        textTransform: 'uppercase',
        color: theme.palette.tertiary[500],
        letterSpacing: '1px',
        fontSize: '12px',
        fontFamily: theme.mind.titleFont
    },
    row: {
        textAlign: 'left',
        '&:hover': {
            background: theme.mind.secondaryBackgroundColor[500],
            cursor: 'pointer'
        }
    },
    lightText: {
        fontFamily: theme.mind.bodyFont,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: theme.palette.text.secondary
    },
    menuButton: {
        padding: '0'
    },
    options: {
        textAlign: 'right'
    },
    inactive: {
        '&:hover': {
            cursor: 'default',
            background: 'none'
        },
        '& th': {
            color: 'rgba(115, 115, 115, 0.5)'
        }
    }
}));

const TableComponent = (props) => {
    const classes = useStyles();
    const {
        rows,
        filter,
        head = [],
        onOptionsClick,
        activeRow,
        activeRows = [],
        empty,
        layout = ''
    } = props;

    const handleClick = (row, idx) => () => {
        if (props.onClick) props.onClick(row, idx);
    };

    const handleRowClick = (row, idx) => {
        if (props.onRowClick) props.onRowClick(row, idx);
    };

    return (
        <React.Fragment>
            <TableContainer>
                <Table
                    className={layout === 'fixed' ? classes.fixedLayout : ''}
                    size="medium"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            {head &&
                                head.map((el, idx) => (
                                    <TableCell
                                        align="left"
                                        key={idx}
                                        className={classes.tableCellHead}
                                    >
                                        {el.label || el}
                                    </TableCell>
                                ))}
                            {onOptionsClick && <TableCell align="left" />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    onClick={handleClick(row, idx)}
                                    className={classNames(
                                        classes.row,
                                        { [classes.inactive]: row[0].active === false },
                                        { [classes.tableRow]: props.onClick },
                                        { [classes.active]: idx === activeRow },
                                        {
                                            [classes.active]: activeRows.some((i) => i === idx)
                                        }
                                    )}
                                >
                                    {row.map((r, index) => (
                                        <TableCell
                                            key={index}
                                            onClick={(e) => handleRowClick(row, idx)}
                                            className={classNames(
                                                [classes.tableCell],
                                                { [classes.strong]: r.strong },
                                                { [classes.lightText]: index !== 0 },
                                                { [classes.noBorder]: r.noBorder },
                                                {
                                                    [classes.mediumSize]: r.size
                                                        ? r.size === 'medium'
                                                        : false
                                                },
                                                {
                                                    [classes.smallSize]: r.size
                                                        ? r.size === 'small'
                                                        : false
                                                }
                                            )}
                                            component="th"
                                            scope="row"
                                        >
                                            {r.label && (
                                                <span className={classes.realValue}>
                                                    {r.label || r}
                                                </span>
                                            )}
                                        </TableCell>
                                    ))}
                                    {onOptionsClick && (
                                        <TableCell
                                            key={idx}
                                            component="th"
                                            scope="row"
                                            className={classes.options}
                                        >
                                            <IconButton
                                                className={classes.menuButton}
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => onOptionsClick(event, row)}
                                            >
                                                <OptionsIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        {!rows.length && empty && (
                            <TableRow className={classes.row}>
                                <TableCell component="th" scope="row">
                                    <Typography>{empty}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {filter && <Pagination query={filter} count={rows.length} />}
        </React.Fragment>
    );
};

export default TableComponent;
