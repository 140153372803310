import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    textblock: {
        width: '375px',
        backgroundColor: '#f3f3f3',
        padding: '30px',
        height: 'fit-content'
    },
    textblockTitle: {
        fontWeight: 'bold'
    }
}));

const QuestionBlock = (props) => {
    const { initial } = props;
    const classes = useStyles();

    if (!initial || !initial.openQuestionAnswer) return '';
    return (
        <div className={classes.textblock}>
            <Typography className={classes.textblockTitle} component="h2" variant="body2">
                {initial ? initial.openQuestion : 'Niet ingevoerd'}
            </Typography>
            <Typography variant="body2">
                {initial ? initial.openQuestionAnswer : 'Niet ingevoerd'}
            </Typography>
        </div>
    );
};

export default QuestionBlock;
