import React, { useEffect, useState } from 'react';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import ParticipantTable from 'components/ParticipantTable';
import { SUBGROUP, ORGANIZATION, LOCATION, GROUP } from './graphql';
import Summary from 'packages/shared/Summary';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { Loader } from 'packages/shared/core';
import { useFilter } from 'hooks';

const ParticipantOverview = (props) => {
    const params = useParams();
    const [participants, setParticipants] = useState([]);
    const [mailOpen, setMailOpen] = useState(false);

    // queries
    const { data: organisationData = {} } = useQuery(ORGANIZATION, {
        variables: {
            page: 1,
            id: params.id,
            ascending: false
        }
    });
    const organisation = organisationData.getOrganisationById;

    const { data: locationData = {} } = useQuery(LOCATION, {
        variables: {
            page: 1,
            id: params.locationId,
            ascending: false
        }
    });
    const location = locationData.getLocationById;

    const { data: groupData = {} } = useQuery(GROUP, {
        variables: {
            page: 1,
            id: params.groupId,
            ascending: false
        }
    });
    const group = groupData.getGroupById;

    const filter = useFilter({
        pagination: { sortProperties: ['state'], direction: false }
    });

    const { data = {}, loading } = useQuery(SUBGROUP, {
        variables: {
            page: filter.query.pagination.page,
            id: params.subgroupId,
            ascending: filter.query.pagination.direction
        }
    });
    const subgroup = data.getSubgroupById;

    useEffect(() => {
        if (subgroup) {
            const _rows = subgroup.formParticipants.items.map((participant) => {
                return [
                    {
                        label: participant.firstName + ' ' + participant.lastName || 'Leeg',
                        id: participant.id,
                        firstName: participant.firstName,
                        lastName: participant.lastName,
                        birthdate: format(new Date(participant.birthdate), 'MM-dd-yyyy') || 'Leeg',
                        email: participant.email
                    },
                    {
                        label: participant.results.length
                    },
                    {
                        label: participant.results.length
                            ? format(
                                  new Date(
                                      participant.results[participant.results.length - 1].date
                                  ),
                                  'dd-MM-yyyy'
                              )
                            : 'Geen'
                    }
                ];
            });

            setParticipants(_rows);
        }
    }, [subgroup]);

    const handleMailClick = () => {
        setMailOpen(true);
    };

    const handleMailClose = () => {
        setMailOpen(false);
    };

    if (loading && !data.length) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid
                title={organisation && organisation.name}
                location={location && location.name}
                group={group && group.name}
                subgroup={subgroup && subgroup.name}
                onMailClick={handleMailClick}
            >
                {subgroup && subgroup.averageEnergizersPerResult.length ? (
                    <Summary
                        title={subgroup && subgroup.name}
                        rows={subgroup ? subgroup.averageEnergizersPerResult : []}
                    />
                ) : (
                    ''
                )}
                <ParticipantTable
                    filter={filter}
                    participants={participants}
                    onClose={handleMailClose}
                    mailOpen={mailOpen}
                />
            </DefaultGrid>
        </Layout>
    );
};

export default ParticipantOverview;
