import { gql } from 'apollo-boost';

export const SUBGROUP = gql`
    query getSubgroupById($id: Long!) {
        getSubgroupById(id: $id) {
            name
            averageEnergizersPerResult {
                averageEnergizerFeeding
                averageEnergizerHome
                averageEnergizerHumour
                averageEnergizerMeaning
                averageEnergizerMoving
                averageEnergizerPassion
                averageEnergizerRelaxing
                averageEnergizerSleeping
                averageEnergizerSocials
                averageEnergizerWork
                averageTotal
            }
            formParticipants {
                firstName
                lastName
                birthdate
                id
                email
                results {
                    date
                }
            }
        }
    }
`;

export const ORGANIZATION = gql`
    query getOrganisationById($id: Long!) {
        getOrganisationById(id: $id) {
            id
            name
        }
    }
`;

export const LOCATION = gql`
    query getLocationById($id: Long!) {
        getLocationById(id: $id) {
            id
            name
        }
    }
`;

export const LOCATIONS = gql`
    query getLocationsByOrganisationId($organisationId: Long!) {
        getLocationsByOrganisationId(organisationId: $organisationId) {
            id
            name
        }
    }
`;

export const GROUPS = gql`
    query getGroupsByLocationId($locationId: Long!) {
        getGroupsByLocationId(locationId: $locationId) {
            id
            name
        }
    }
`;

export const GROUP = gql`
    query getGroupById($id: Long!) {
        getGroupById(id: $id) {
            id
            name
        }
    }
`;

export const SUBGROUPS = gql`
    query getSubgroupsByGroupId($groupId: Long!) {
        getSubgroupsByGroupId(groupId: $groupId) {
            id
            name
        }
    }
`;

export const ORGANISATIONS = gql`
    query getOrganisations {
        getOrganisations {
            id
            name
            locations {
                id
                name
                groups {
                    id
                    name
                    subgroups {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const UPDATE_PARTICIPANT = gql`
    mutation updateFormParticipant($updateFormParticipant: UpdateFormParticipantInput!) {
        updateFormParticipant(updateFormParticipant: $updateFormParticipant) {
            id
            birthdate
            email
            firstName
            lastName
        }
    }
`;

export const MOVE_PARTICIPANT = gql`
    mutation moveFormParticipant($moveFormParticipant: MoveFormParticipantInput!) {
        moveFormParticipant(moveFormParticipantDto: $moveFormParticipant) {
            id
        }
    }
`;

export const MAIL_PARTICIPANTS = gql`
    mutation sendResultsToFormParticipants($formParticipantIds: [Long!]!, $mailType: String!) {
        sendResultsToFormParticipants(formParticipantIds: $formParticipantIds, mailType: $mailType)
    }
`;
