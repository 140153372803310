import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '240px',
        height: '56px',
        border: '  1px dashed rgba(151,151,151,0.5)',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: 'none',
        color: 'rgba(151,151,151,0.9)',
        marginTop: '15px',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderColor: 'rgb(151,151,151)'
        }
    }
}));

const AddButton = (props) => {
    const { onClick, title } = props;
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            onClick={onClick}
            className={classes.root}
            endIcon={<AddIcon />}
        >
            {title}
        </Button>
    );
};

export default AddButton;
