import React from 'react';
import { AddButton } from './parts';
import SubGroup from './SubGroup';

const SubGroups = (props) => {
    const { groups = [], onUpdate, initial } = props;

    const handleAddRow = (type, id, groupId) => {
        onUpdate({
            action: type,
            id: id || null,
            groupId: groupId || null
        });
    };

    const handleTextfieldChange = (type, value, id, groupId, subgroupId) => {
        onUpdate({
            action: type,
            id: id || null,
            groupId: groupId || null,
            subgroupId: subgroupId || null,
            value: value
        });
    };

    return (
        <div>
            {groups.map((group) => (
                <React.Fragment key={group.id}>
                    {group.id && (
                        <SubGroup
                            data={group}
                            initial={initial}
                            onNewRow={handleAddRow}
                            onTextfieldChange={handleTextfieldChange}
                        />
                    )}
                </React.Fragment>
            ))}
            {!initial && <AddButton title="Naam toevoegen" onClick={() => handleAddRow('add')} />}
        </div>
    );
};

export default SubGroups;
