import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OptionsIcon from '@mui/icons-material/MoreHoriz';
import Checkbox from '@mui/material/Checkbox';
import UsersTableHead from './UsersTableHead';
import Pagination from 'packages/shared/Pagination';

const useStyles = makeStyles((theme) => ({
    table: {
        display: 'flex',
        flexDirection: 'column'
    },
    optionsIcon: {
        cursor: 'pointer',
        transition: 'color .3s',
        '&:hover': {
            color: theme.palette.secondary[500]
        }
    },
    tableRow: {
        cursor: 'pointer',
        transition: 'background, .3s',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '5px 0',
        fontFamily: theme.mind.bodyFont,
        fontSize: '14px',

        '&:hover': {
            background: theme.palette.secondary[50]
        }
    },
    active: {
        background: theme.palette.secondary[50]
    },
    strong: {
        fontFamily: theme.mind.defaultTitleFont,
        fontWeight: 600,
        color: theme.palette.primary[500]
    },
    noBorder: {
        borderBottom: 0
    },
    empty: {
        padding: '20px'
    },
    row: {
        textAlign: 'left',
        '&:hover': {
            background: theme.mind.secondaryBackgroundColor[500],
            cursor: 'pointer'
        }
    },
    tableCell: {
        textTransform: 'capitalize',
        width: '33.3%'
    },
    lightText: {
        fontFamily: theme.mind.bodyFont,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: theme.palette.text.secondary
    },
    menuButton: {
        padding: '0',
        position: 'absolute',
        right: '10px',
        border: 'none'
    },
    options: {
        textAlign: 'right'
    },
    CheckboxCell: {
        width: '33.3%',
        padding: '0',
        display: 'flex',
        alignItems: 'center'
    }
}));

const UsersTable = (props) => {
    const { rows, onOptionsClick, filter } = props;

    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n[0]);
            setSelected(newSelecteds);
            props.onCheckboxClick(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        props.onCheckboxClick(newSelected);
        setSelected(newSelected);
    };

    const handleRowClick = (id) => {
        if (props.onRowClick) props.onRowClick(id);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div className={classes.root}>
            <div>
                <div
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                >
                    <UsersTableHead
                        classes={classes}
                        query={filter}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={rows.length}
                    />
                    <div>
                        {rows.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <div tabIndex={-1} key={index} className={classes.tableRow}>
                                    {row.map((r, index) => (
                                        <React.Fragment key={index}>
                                            {r.id ? (
                                                <div className={classes.CheckboxCell}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isSelected(r)}
                                                        inputProps={{
                                                            'aria-labelledby': labelId
                                                        }}
                                                        onClick={(event) => handleClick(event, r)}
                                                    />
                                                    <div onClick={(e) => handleRowClick(r.id)}>
                                                        {r.label}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={classes.tableCell}>{r.label}</div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {row[0].active !== false && (
                                        <div className={classes.menuButton}>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => onOptionsClick(event, row)}
                                            >
                                                <OptionsIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Pagination query={filter} count={rows.length} />
        </div>
    );
};

export default UsersTable;
