import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%'
    },
    iconRoot: {
        display: 'flex'
    },
    icon: {
        marginRight: '10px',
        transform: 'translateY(-2px)',
        color: theme.palette.primary[500]
    },
    label: {
        display: 'flex',
        alignItems: 'center'
    },
    loading: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: theme.palette.primary[50],
            zIndex: 99
        }
    }
}));

const InputField = forwardRef((props, ref) => {
    const {
        className,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        form = false,
        id,
        label = false,
        name,
        onChange,
        placeholder,
        icon: Icon = false,
        clearField,
        type,
        initialValue = '',
        fullWidth = false,
        variant = 'outlined',
        hint,
        margin = 'normal',
        loading,
        multiline = false,
        rows = 1,
        readOnly,
        optional,
        value: _value = '',
        ...otherProps
    } = props;
    const classes = useStyles(className);

    //state hooks
    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [value, setValue] = useState(initialValue || _value);

    //effect hooks
    useEffect(() => {
        setError(errorFromProps);
        setErrorMessage(errorMessageFromProps);
    }, [errorFromProps, errorMessageFromProps]);

    useEffect(() => {
        if (initialValue && !loading) {
            setValue(initialValue);
            if (form) form.onFieldChange({ key: name, value: initialValue });
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        if (!initialValue && value !== _value) {
            setValue(_value);
            if (form) form.onFieldChange({ key: name, value: _value });
        }
        // eslint-disable-next-line
    }, [_value]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, name]);

    //functions
    const handleChange = (event) => {
        const val = event.target.value;
        if (!_value) setValue(val);

        if (form) form.onFieldChange({ key: name, value: val });

        if (onChange) {
            onChange(val);
        }
    };

    const handleClick = (event) => {
        setValue('');
    };

    const handleFocus = (event) => {
        setValue('');
    };

    const handleClickOutside = (event) => {
        if (event.target.value === '' && initialValue) {
            setValue(initialValue);
        }
    };

    return (
        <TextField
            ref={ref}
            id={id}
            label={
                <span
                    className={classNames(classes.label, {
                        [classes.iconRoot]: Icon
                    })}
                >
                    {Icon && <Icon className={classes.icon} />}
                    {label}
                </span>
            }
            className={classNames(
                classes.textField,
                { [classes.fullWidth]: fullWidth },
                { [classes.loading]: loading },
                className
            )}
            error={error}
            helperText={error ? errorMessage : hint || ''}
            name={name}
            margin={margin}
            onClick={clearField ? handleClick : null}
            onBlur={clearField ? handleClickOutside : null}
            onChange={handleChange}
            onFocus={clearField ? handleFocus : null}
            type={type}
            value={value}
            size="medium"
            variant={variant}
            multiline={multiline}
            rows={rows}
            disabled={readOnly}
            InputProps={{
                style: { fontSize: 16 },
                autoComplete: 'off'
            }}
            {...otherProps}
        />
    );
});

export default InputField;
