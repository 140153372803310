import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    legend: {
        minWidth: '240px',
        border: '1px solid rgba(38, 38, 38, 0.2)',
        maxHeight: '460px',
        padding: '40px 150px 40px 40px'
    },
    legendWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px'
    },
    legendItem: {
        marginBottom: '40px'
    },
    first: {
        width: '100%',
        height: '3px',
        background: '#000'
    },

    second: {
        // backgroundImage: "url(" + data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='25' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e + ")"
    },

    fourth: {
        width: '100%',
        height: '2px',
        background: '#000'
    }
}));

const Legend = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.legend}>
            <div className={classes.legend__wrapper}>
                <Typography variant="subtitle1">Legenda</Typography>
                <div className={classes.legendItem}>
                    <div className={classes.first} />
                    <Typography>1e meting (11-10-2015)</Typography>
                </div>
                <div className={classes.legendItem}>
                    <div className={classes.second} />
                    <Typography>2e meting (11-10-2015)</Typography>
                </div>
                <div className={classes.legendItem}>
                    <div className={classes.third} />
                    <Typography>3e meting (11-10-2015)</Typography>
                </div>
                <div className={classes.legendItem}>
                    <div className={classes.fourth} />
                    <Typography>4e meting (11-10-2015)</Typography>
                </div>
                <div className={classes.legendItem}>
                    <div className={classes.fifth} />
                    <Typography>5e meting (11-10-2015)</Typography>
                </div>
            </div>
        </div>
    );
};

export default Legend;
