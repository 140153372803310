import React from 'react';
import LocationField from './LocationField';
import GroupField from './GroupField';
import SubgroupField from './SubgroupField';

const OrganisationRow = (props) => {
    const { form, onChange, subgroups = {} } = props;

    return (
        <React.Fragment>
            <LocationField
                form={form}
                id={subgroups.organisation}
                onChange={(data) => onChange(data, 'locationId')}
            />
            <GroupField
                form={form}
                id={subgroups.locationId}
                onChange={(data) => onChange(data, 'groupId')}
            />
            <SubgroupField
                form={form}
                id={subgroups.groupId}
                onChange={(data) => onChange(data, 'subgroupId')}
            />
        </React.Fragment>
    );
};

export default OrganisationRow;
