import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddButton } from './parts';
import { TextField } from 'packages/shared/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%'
    }
}));

const SubGroup = (props) => {
    const { data = {}, onNewRow, onTextfieldChange, initial } = props;
    const classes = useStyles();

    const handleAddRow = () => {
        onNewRow('addGroup', data.id);
    };
    const handleAddSubgroups = (group) => {
        onNewRow('addSubgroup', data.id, group.id);
    };

    const handleUrl = (value, groupId, subGroupId) => {
        onTextfieldChange('url', value, data.id, groupId, subGroupId);
    };

    const handleTextfield = (value, groupId, subGroupId) => {
        onTextfieldChange('textfield', value, data.id, groupId, subGroupId);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <TextField
                    className={classes.textField}
                    label="Naam"
                    name="location"
                    initialValue={data.name || ''}
                    onChange={(value) => handleTextfield(value)}
                />
            </Grid>
            <Grid item xs={9}>
                {data.groups &&
                    data.groups.map((group) => (
                        <React.Fragment key={group.id}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        className={classes.textField}
                                        label="Groep"
                                        name="group"
                                        initialValue={group.name || ''}
                                        onChange={(value) => handleTextfield(value, group.id)}
                                    />
                                </Grid>
                                <Grid item xs={group.subgroups && group.subgroups.length ? 8 : 4}>
                                    {group.subgroups &&
                                        group.subgroups.map((subgroup) => (
                                            <Grid key={subgroup.id} container spacing={3}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        className={classes.textField}
                                                        label="Subgroep"
                                                        name="subGroup"
                                                        initialValue={subgroup.name || ''}
                                                        onChange={(value) =>
                                                            handleTextfield(
                                                                value,
                                                                group.id,
                                                                subgroup.id
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        className={classes.textField}
                                                        label="Formulier url 3"
                                                        name="formUrl"
                                                        initialValue={subgroup.formUrl || ''}
                                                        onChange={(val) =>
                                                            handleUrl(val, group.id, subgroup.id)
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    {!initial && (
                                        <AddButton
                                            title="Subgroep toevoegen"
                                            onClick={() => handleAddSubgroups(group)}
                                        />
                                    )}
                                </Grid>
                                {(!group.subgroups || !group.subgroups.length) && (
                                    <Grid item xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label="Formulier url 2"
                                            name="formUrl"
                                            onChange={(val) => handleUrl(val, group.id)}
                                            initialValue={group.formUrl || ''}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                {(!data.groups || !data.groups.length) && (
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            {!initial ? (
                                <AddButton title="Groep toevoegen" onClick={handleAddRow} />
                            ) : (
                                ''
                            )}
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <TextField
                                className={classes.textField}
                                label="Formulier url 1"
                                name="formUrl"
                                onChange={handleUrl}
                                initialValue={data.formUrl || ''}
                            />
                        </Grid>
                    </Grid>
                )}
                {data.groups && !initial && (
                    <AddButton title="Groep toevoegen" onClick={handleAddRow} />
                )}
            </Grid>
        </Grid>
    );
};

export default SubGroup;
