import React from 'react';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '100%',
        maxWidth: '430px',
        borderRight: `1px solid ${theme.mind.defaultBorderColor[50]}`,
        padding: '40px 50px'
    },
    absolute: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute'
        }
    },
    large: {
        minWidth: '80%'
    }
}));

const DrawerContainer = (props) => {
    const { position, children, open = false, variant = 'temporary', className, size } = props;
    const classes = useStyles();

    return (
        <Drawer
            variant={variant}
            anchor="right"
            classes={{
                paper: classNames(className, classes.drawerPaper, {
                    [classes.absolute]: position === 'absolute',
                    [classes.large]: size === 'lg'
                })
            }}
            open={open}
        >
            {children}
        </Drawer>
    );
};

export default DrawerContainer;
