import React from 'react';
import SidebarGrid from 'packages/app/SidebarGrid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: '265px calc(100% - 265px)',
        width: '100vw'
    }
}));

const Layout = (props) => {
    const classes = useStyles();
    const { children } = props;

    return (
        <div className={classes.root}>
            <SidebarGrid />
            {children}
        </div>
    );
};

export default Layout;
