import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import Summary from 'packages/shared/Summary';
import { PARTICIPANT, REMOVE_RESULT } from './graphql';
import {
    EnergyLevel,
    InfoBlock,
    QuestionBlock,
    DeleteMeasurement,
    UpdateMeasurement,
    Chart
} from './parts';
import { useSnackbar } from 'notistack';
import { Typography, MenuItem, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import keycloak from 'network/keycloak';
import { format } from 'date-fns';
import { Loader, Drawer } from 'packages/shared/core';

const useStyles = makeStyles((theme) => ({
    rowWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    chartWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '50px'
    },
    rowTitle: {
        display: 'flex',
        flex: '0 0 100%'
    }
}));

const Paticipant = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const params = useParams();
    const [infoRows, setInfoRows] = useState([]);
    const [energyLevel, setEnergyLevel] = useState([]);
    const [anchorEl, setAnchorEl] = useState(false);
    const [anchorPdf, setAnchorPdf] = useState(false);
    const [summaryId, setSummaryId] = useState(null);
    const [testDate, setTestDate] = useState('');
    const [deleteOpen, setDeleteopen] = useState(false);
    const [addUpdateOpen, setAddUpdateOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const saveAs = require('file-saver');

    const {
        data = {},
        refetch,
        loading
    } = useQuery(PARTICIPANT, {
        variables: {
            id: params.participantId
        }
    });

    const participant = data.getFormParticipantById;

    const [deleteResult] = useMutation(REMOVE_RESULT);

    useEffect(() => {
        if (participant) {
            const _rows = [
                participant.firstName + ' ' + participant.lastName || 'Leeg',
                participant.email || 'Leeg',
                participant.organisation.name || 'Leeg',
                format(new Date(participant.birthdate), 'dd-MM-yyyy') || 'Leeg'
            ];

            setInfoRows(_rows);
        }
    }, [participant]);

    useEffect(() => {
        if (participant && participant.results) {
            const date = format(new Date(participant.results.slice(-1)[0].date), 'yyyy MM dd');

            setTestDate(date);
        }
    }, [participant]);

    useEffect(() => {
        if (participant) {
            const _rows = participant.results
                .slice(-2)
                .reverse()
                .map((item, idx) => {
                    return {
                        title: idx === 0 ? 'Laatste meting' : 'Vorige meting',
                        date: item.date,
                        total: item.total
                    };
                });

            setEnergyLevel(_rows);
        }
    }, [participant]);

    const handlePdfClick = (event) => {
        setAnchorPdf(event.currentTarget);
    };
    const downloadPdfClick = (event) => () => {
        const uri = process.env.REACT_APP_MIND_API_DOMAIN;
        setAnchorPdf(false);

        switch (event) {
            case 'pdf':
                fetch(`${uri}/youniverse/pdf/${params.participantId}`, {
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${keycloak.token}` || null
                    },
                    responseType: 'blob'
                })
                    .then((response) => response.blob())
                    .then((blob) =>
                        saveAs(
                            blob,
                            `${
                                participant && participant.firstName + ' ' + participant.lastName
                            } ${testDate}.pdf`
                        )
                    );
                break;

            case 'png':
                fetch(`${uri}/youniverse/png/${params.participantId}`, {
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${keycloak.token}` || null
                    },
                    responseType: 'blob'
                })
                    .then((response) => response.blob())
                    .then((blob) =>
                        saveAs(
                            blob,
                            `${
                                participant && participant.firstName + ' ' + participant.lastName
                            } ${testDate}.png`
                        )
                    );
                break;
            default:
                console.log('');
        }
    };

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSummaryId(id);
    };

    const handleClose = () => {
        setAnchorPdf(false);
        setAnchorEl(false);
        setDeleteopen(false);
        setAddUpdateOpen(false);
    };

    const handleOpenDelete = () => {
        setDeleteopen(true);
    };

    const handleOpenUpdate = () => {
        setAddUpdateOpen(true);
    };

    const handleDelete = () => {
        deleteResult({
            variables: {
                id: summaryId
            }
        })
            .then((r) => {
                if (!r.errors) {
                    handleSuccess();
                    refetch();
                } else {
                    handleError();
                }
            })
            .catch((e) => handleError());
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('GENERAL.SUCCESSMESSAGE')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('GENERAL.DENYMESSAGE')
        });
    };

    if (loading && !data.length) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid
                title={(participant && participant.organisation.name) || ''}
                location={participant && participant.location ? participant.location.name : ''}
                group={participant && participant.group ? participant.group.name : ''}
                subgroup={participant && participant.subgroup ? participant.subgroup.name : ''}
                participant={
                    (participant && participant.firstName + ' ' + participant.lastName) || ''
                }
                onPdfClick={handlePdfClick}
            >
                <Menu
                    className={classes.PopupMenu}
                    keepMounted
                    anchorEl={anchorPdf}
                    open={Boolean(anchorPdf)}
                    onClose={handleClose}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <MenuItem onClick={downloadPdfClick('pdf')}>
                        {t('PARTICIPANT.DOWNLOADPDF')}
                    </MenuItem>
                    <MenuItem onClick={downloadPdfClick('png')}>
                        {t('PARTICIPANT.DOWNLOADPNG')}
                    </MenuItem>
                </Menu>
                <InfoBlock initial={participant} rows={infoRows} />

                {participant && participant.results.length ? (
                    <React.Fragment>
                        <div className={classes.chartWrap}>
                            <Chart initial={participant} />
                        </div>
                        <div className={classes.rowWrap}>
                            <Summary
                                customTitle={t('PARTICIPANT.DEVELOPMENTOF')}
                                title={
                                    participant &&
                                    participant.firstName + ' ' + participant.lastName
                                }
                                headerTitle={t('SUMMARY.MEASUREMENT')}
                                rows={participant ? participant.results : []}
                                onOptionsClick={handleMenuClick}
                            />

                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={{ zIndex: '0' }}
                            >
                                <MenuItem onClick={handleOpenUpdate}>
                                    {t('GENERAL.CHANGE')}
                                </MenuItem>
                                <MenuItem onClick={handleOpenDelete}>
                                    {t('PARTICIPANT.DELETEMEASUREMENT')}
                                </MenuItem>
                            </Menu>

                            <Drawer position="absolute" open={Boolean(addUpdateOpen)}>
                                <UpdateMeasurement
                                    onClose={handleClose}
                                    initial={
                                        summaryId !== null
                                            ? participant.results.filter(
                                                  (row) => row.id === summaryId
                                              )
                                            : undefined
                                    }
                                />
                            </Drawer>

                            <DeleteMeasurement
                                onSubmit={handleDelete}
                                open={Boolean(deleteOpen)}
                                onClose={handleClose}
                                initial={
                                    summaryId !== null
                                        ? participant.results.filter((row) => row.id === summaryId)
                                        : undefined
                                }
                            />
                        </div>
                        <div className={classes.rowWrap}>
                            <Typography className={classes.rowTitle} component="h2" variant="h5">
                                {t('PARTICIPANT.ENERGYLEVEL')}
                            </Typography>
                            <EnergyLevel initial={energyLevel} />
                            <QuestionBlock
                                initial={
                                    participant && participant.results
                                        ? participant.results[participant.results.length - 1]
                                        : []
                                }
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    ''
                )}
            </DefaultGrid>
        </Layout>
    );
};

export default Paticipant;
