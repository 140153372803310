import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tableItemCell: {
        textAlign: 'center',
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        background: 'rgba(38,38,38,0.1)',
        minHeight: '50px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        color: theme.palette.tertiary[500],
        justifyContent: 'center',

        '&:first-of-type': {
            justifyContent: 'flex-start'
        }
    },
    tableItemCellTotal: {
        background: theme.palette.tertiary[500],
        color: theme.mind.white[500],
        textAlign: 'center',
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        minHeight: '50px',
        fontSize: '15px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const SummaryItem = (props) => {
    const { result, total } = props;
    const classes = useStyles();

    return (
        <div className={total ? classes.tableItemCellTotal : classes.tableItemCell}>
            {result ? result.toFixed(1) : ''}
            {total || ''}
        </div>
    );
};

export default SummaryItem;
