import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(1)}px ${theme.spacing(-2.5)}px`
    },
    toolbar: {
        flexFlow: 'row wrap',
        color: theme.palette.primary[200],
        justifyContent: 'flex-end',
        [theme.breakpoints.down(768)]: {
            justifyContent: 'center'
        }
    },
    actions: {
        [theme.breakpoints.down(768)]: {
            marginLeft: 0
        }
    },
    caption: {
        margin: '0 0 0 10px'
    },
    select: {
        flex: '0 0 auto'
    }
}));

const Pagination = (props) => {
    const { count = 0, query: queryObj = {}, rowsPerPage = [] } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { query = {} } = queryObj;
    const { pagination = false } = query;

    const handleChangePage = (event, newPage) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (queryObj.setQuery)
            queryObj.setQuery({
                pagination: { page: newPage + 1 }
            });
    };

    const handleChangeRowsPerPage = (event) => {
        if (queryObj.setQuery)
            queryObj.setQuery({
                pagination: {
                    page: 0,
                    pageSize: parseInt(event.target.value, 10)
                }
            });
    };

    if (!pagination) return '';
    return (
        <div>
            <TablePagination
                rowsPerPageOptions={rowsPerPage}
                count={-1}
                rowsPerPage={parseInt(pagination.pageSize || 50)}
                page={count ? parseInt(pagination.page - 1) : 0}
                component="div"
                classes={{
                    root: classes.root,
                    toolbar: classes.toolbar,
                    actions: classes.actions,
                    caption: classes.caption
                }}
                labelRowsPerPage={t('pagination.rowsPerPage')}
                SelectProps={{
                    inputProps: { 'aria-label': t('pagination.rowsPerPage') },
                    native: true,
                    className: classes.select
                }}
                labelDisplayedRows={({ from, to }) => {
                    return '' + from + '-' + to;
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default Pagination;
