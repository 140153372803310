import { gql } from 'apollo-boost';

export const LOCATIONS = gql`
    query getPaginatedLocationsByOrganisationId($organisationId: Long!, $page: Int!) {
        getPaginatedLocationsByOrganisationId(organisationId: $organisationId, page: $page) {
            items {
                id
                name
                amountFormParticipants
                groups {
                    id
                    name
                }
            }
        }
    }
`;

export const ORGANIZATION = gql`
    query getOrganisationById($id: Long!, $page: Int!, $ascending: Boolean!) {
        getOrganisationById(id: $id, page: $page, ascending: $ascending) {
            id
            name
            amountFormParticipants
            averageEnergizersPerResult {
                averageEnergizerFeeding
                averageEnergizerHome
                averageEnergizerHumour
                averageEnergizerMeaning
                averageEnergizerMoving
                averageEnergizerPassion
                averageEnergizerRelaxing
                averageEnergizerSleeping
                averageEnergizerSocials
                averageEnergizerWork
                averageTotal
            }
            locations {
                groups {
                    name
                    id
                }
            }
        }
    }
`;
