import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'packages/app/Layout';
import DefaultGrid from 'packages/app/DefaultGrid';
import Table from 'packages/shared/Table';
import { LOCATIONS, ORGANIZATION } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import Summary from 'packages/shared/Summary';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader } from 'packages/shared/core';

const LocationOverview = (props) => {
    const { t } = useTranslation();
    const params = useParams();
    const history = useNavigate();
    const [rows, setRows] = useState([]);

    // queries
    const { data = {}, loading } = useQuery(ORGANIZATION, {
        variables: {
            page: 1,
            id: params.id,
            ascending: false
        }
    });
    const organization = data.getOrganisationById;

    const { data: locations = {} } = useQuery(LOCATIONS, {
        variables: {
            page: 1,
            organisationId: params.id
        }
    });
    const locationList = locations.getPaginatedLocationsByOrganisationId;

    const tableHead = [t('GENERAL.GROUP'), t('GENERAL.PARTICIPANTS')];

    useEffect(() => {
        if (locationList) {
            const _rows = locationList.items.map((location) => {
                return [
                    {
                        label: location.name || 'Leeg',
                        id: location.id
                    },
                    {
                        label: location.amountFormParticipants
                    }
                ];
            });

            setRows(_rows);
        }
    }, [locationList]);

    const handleRowClick = (data) => {
        history(`/organisation/${params.id}/location/${data[0].id}`);
    };

    if (loading && !data.length) return <Loader centered />;
    return (
        <Layout>
            <DefaultGrid title={organization && organization.name}>
                {organization && organization.averageEnergizersPerResult.length ? (
                    <Summary
                        title={organization && organization.name}
                        rows={organization ? organization.averageEnergizersPerResult : []}
                    />
                ) : (
                    ''
                )}
                {rows.length ? (
                    <Table layout="fixed" head={tableHead} rows={rows} onClick={handleRowClick} />
                ) : (
                    ''
                )}
            </DefaultGrid>
        </Layout>
    );
};

export default LocationOverview;
