import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.mind.defaultFont,
        color: 'rgba(255, 255, 255, 0.5)',
        cursor: 'pointer',
        fontSize: '18px',
        lineHeight: '36px',
        transition: 'color .2s',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            color: theme.palette.secondary[500]
        }
    },
    active: {
        color: theme.mind.white[500]
    }
}));

const LinkComponent = React.forwardRef((props, ref) => {
    const {
        children,
        to,
        disabled,
        className,
        color = 'default',
        active = false,
        ...otherProps
    } = props;
    const classes = useStyles();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    if (!to || disabled)
        return (
            <div
                onClick={handleClick}
                className={classNames(
                    classes.root,
                    classes[color],
                    { [classes.active]: active },
                    className
                )}
                ref={ref}
                {...otherProps}
            >
                {children}
            </div>
        );
    if (/^https?:\/\//.test(to))
        return (
            <a
                ref={ref}
                className={classNames(
                    classes.root,
                    classes[color],
                    { [classes.active]: active },
                    className
                )}
                href={to}
                {...otherProps}
            >
                {children}
            </a>
        );

    return (
        <Link
            className={classNames(
                classes.root,
                classes[color],
                { [classes.active]: active },
                className
            )}
            to={to}
            ref={ref}
            {...otherProps}
        >
            {children}
        </Link>
    );
});

export default LinkComponent;
