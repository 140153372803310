import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SelectField } from 'packages/shared/core';
import Close from '@mui/icons-material/Close';
import { useForm } from 'hooks';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { MOVE_PARTICIPANT } from '../../graphql';
import OrganisationRow from './OrganisationRow';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '20px'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    closeBg: {
        width: '40px',
        height: '40px',
        background: theme.mind.secondaryBackgroundColor[500]
    },
    button: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '15px',

        '&:hover': {
            background: theme.palette.primary[900]
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    select: {
        maxWidth: 'calc(33.3% - 30px)',
        width: '100%'
    }
}));

//queries
const UpdateParticipant = (props) => {
    const { onClose, initial, organisations } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectName, setSelectName] = useState('');
    const [selectId, setSelectId] = useState('');

    const [form, moveParticipantForm] = useForm({
        organisation: {
            required: true
        },
        location: {
            required: false
        },
        group: {
            required: false
        },
        subgroup: {
            required: false
        }
    });

    const [updateParticipant] = useMutation(MOVE_PARTICIPANT, {
        refetchQueries: ['getSubgroupById', 'getLocationById', 'getGroupById']
    });

    const handleSubmit = () => {
        moveParticipantForm()
            .then((r) => {
                updateParticipant({
                    variables: {
                        moveFormParticipant: {
                            id: initial.id,
                            [selectName]: selectId
                        }
                    }
                }).then((resp) => {
                    if (!resp.errors) {
                        handleSuccess();
                    } else {
                        handleError();
                    }
                });
            })
            .catch((e) => handleError());
    };

    const handleSuccess = () => {
        enqueueSnackbar({
            variant: 'success',
            message: t('PARTICIPANT.REPLACED')
        });
    };

    const handleError = () => {
        enqueueSnackbar({
            variant: 'error',
            message: t('PARTICIPANT.NOTREPLACED')
        });
    };

    //reducers
    const [selectObject, setSelectObject] = useReducer((state, actions) => {
        switch (actions.action) {
            case 'addOrganisation':
                return {
                    ...state,
                    ...actions.value
                };
            default:
                return state;
        }
    }, []);

    //function
    const handleChange = (state, name) => {
        setSelectName(name);

        setSelectId(state);

        setSelectObject({
            action: 'addOrganisation',
            value: {
                [name]: state
            }
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="h1" component="h2">
                    {t('PARTICIPANT.MOVE')}
                </Typography>
                <IconButton className={classes.closeBg} aria-label="delete" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <div className={classes.row}>
                <SelectField
                    label={t('PARTICIPANT.ORGANISATION')}
                    className={classes.select}
                    options={
                        organisations &&
                        organisations.map((o) => {
                            return {
                                label: o.name,
                                value: o.id
                            };
                        })
                    }
                    name="organisation"
                    form={form}
                    onChange={(data) => handleChange(data, 'organisation')}
                />
            </div>
            <div className={classes.row}>
                <Typography variant="body2">(Sub)groep(en)</Typography>
            </div>
            <div className={classes.row}>
                <OrganisationRow
                    form={form}
                    subgroups={selectObject}
                    onChange={(data, name) => handleChange(data, name)}
                />
            </div>
            <div className={classes.row}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('PARTICIPANT.MOVE')}
                </Button>
            </div>
        </div>
    );
};

export default UpdateParticipant;
