import { createCustomTheme, createColor } from 'packages/shared/theme';
import { createTheme as createMuiTheme } from '@mui/material/styles';

const contentWidthXL = '1600px';
const contentWidthL = '1350px';
const contentWidthM = '1050px';
const contentWidthS = '700px';
const contentWidthXS = '320px';

//colors
const primary = createColor('#DD0000');
const secondary = createColor('#F4835E');
const tertiary = createColor('#262626');
const defaultContentColor = createColor('#262626');
const secondaryContentColor = createColor('#737373');
const defaultTitleColor = primary;
const defaultBackgroundColor = createColor('#DD0000');
const secondaryBackgroundColor = createColor('#F3F3F3');
const defaultBorderColor = createColor('#D4D4D4');
const white = createColor('#ffffff');
const errorColor = createColor('#EC5555');
const successColor = createColor('#5DC36A');
const openStateColor = createColor('#5DC36A');
const activeMachine = createColor('#14B813');
const disabledMachine = createColor('#EC5555');
const buttonColor = createColor('#4599F2');

const newTheme = createMuiTheme();
// fonts
const bodyFont = 'Open Sans';
const titleFont = 'Roboto Slab';

//margins
const defaultTitleMargin = '10px';

//wrapper
const defaultWrapper = {
    maxWidth: contentWidthM,
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    [newTheme.breakpoints.up('md')]: {
        paddingLeft: '25px',
        paddingRight: '25px'
    }
};
const extraSmallWrapper = {
    ...defaultWrapper,
    maxWidth: contentWidthXS
};
const smallWrapper = {
    ...defaultWrapper,
    maxWidth: contentWidthS
};
const largeWrapper = {
    ...defaultWrapper,
    maxWidth: contentWidthL
};
const extraLargeWrapper = {
    ...defaultWrapper,
    maxWidth: contentWidthXL
};
const fullWidthWrapper = {
    ...defaultWrapper,
    maxWidth: '100%'
};

const theme = createMuiTheme(newTheme, {
    typography: {
        fontFamily: [
            bodyFont,
            titleFont,
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),

        root: {
            fontFamily: bodyFont
        },
        button: {
            fontFamily: bodyFont,
            textTransform: 'capitalize',
            fontWeight: '600',
            fontSize: '15px'
        },
        h1: {
            fontFamily: titleFont,
            fontSize: '30px',
            fontWeight: '400',
            lineHeight: '35px',
            marginBottom: '10px',
            color: defaultContentColor[500]
        },
        h2: {
            fontFamily: titleFont,
            fontSize: '22px',
            fontWeight: '600',
            lineHeight: '30px',
            color: defaultTitleColor[500],
            [newTheme.breakpoints.up('md')]: {
                lineHeight: '45px'
            }
        },
        h3: {
            fontFamily: titleFont,
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '24px',
            color: defaultTitleColor[500],
            paddingBottom: '8px'
        },
        h4: {
            fontFamily: titleFont,
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '32px'
        },
        h5: {
            fontFamily: bodyFont,
            fontSize: '18px',
            fontWeight: 'bold',
            letterSpacing: '0',
            lineHeight: '34px',
            color: defaultTitleColor[500],
            marginBottom: '10px'
        },
        h6: {
            fontFamily: titleFont,
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '24px',
            color: defaultTitleColor[500]
        },
        body1: {
            fontFamily: bodyFont,
            fontSize: '16px',
            lineHeight: '28px',
            color: defaultContentColor[500]
        },
        body2: {
            fontFamily: bodyFont,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            color: defaultContentColor[500]
        },
        subtitle1: {
            textAlign: 'left!important',
            textTransform: 'uppercase',
            color: tertiary[500],
            letterSpacing: '1px',
            fontSize: '12px',
            fontFamily: titleFont,
            fontWeight: '500',
            marginBottom: '15px'
        },
        subtitle2: {
            fontFamily: bodyFont,
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
            color: defaultContentColor[500]
        },
        caption: {
            fontFamily: bodyFont,
            textTransform: 'uppercase',
            color: defaultContentColor[200],
            letterSpacing: '2px',
            fontSize: '12px',
            fontWeight: '600'
        },
        paragraph: {
            marginBottom: '26px'
        },
        colorTextSecondary: {
            color: secondaryContentColor[500]
        }
    },
    palette: {
        primary: primary,
        secondary: secondary,
        tertiary: tertiary,
        error: errorColor,
        success: successColor,
        text: {
            primary: defaultContentColor[500],
            secondary: secondaryContentColor[500]
        }
    },
    mind: createCustomTheme({
        defaultBackgroundColor: defaultBackgroundColor,
        secondaryBackgroundColor: secondaryBackgroundColor,
        defaultBorderColor: defaultBorderColor,
        white: white,
        defaultWrapper: defaultWrapper,
        largeWrapper: largeWrapper,
        extraLargeWrapper: extraLargeWrapper,
        smallWrapper: smallWrapper,
        extraSmallWrapper: extraSmallWrapper,
        fullWidthWrapper: fullWidthWrapper,
        defaultTitleMargin: defaultTitleMargin,
        bodyFont: bodyFont,
        titleFont: titleFont,
        defaultTitleColor: defaultTitleColor,
        openStateColor: openStateColor,
        activeMachine: activeMachine,
        disabledMachine: disabledMachine,
        buttonColor: buttonColor
    }),
    overrides: {
        MuiCardContent: {
            root: {
                padding: '0'
            }
        },
        MuiButton: {
            root: {
                fontFamily: bodyFont,

                '&:hover': {
                    backgroundColor: primary[300]
                }
            },
            containedPrimary: {
                background: primary[500],
                fontSize: '15px',
                fontWeight: '600',
                boxShadow: 'none'
            },
            outlinedPrimary: {
                color: secondaryContentColor[500],
                borderColor: secondaryContentColor[500],
                fontSize: '15px',
                fontWeight: '600',
                boxShadow: 'none'
            },
            label: {
                textTransform: 'capitalize'
            },
            textSecondary: {
                color: buttonColor[500],
                fontSize: '12px',
                letterSpacing: '2px',
                fontWeight: '600'
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '280px'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '20px 10px 20px 20px',

                '&:first-child': {
                    paddingTop: '20px'
                }
            }
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: `1px solid ${defaultBorderColor[500]}`
                }
            }
        }
    }
});

export default theme;
