import { gql } from 'apollo-boost';

export const ADD_USER = gql`
    mutation createUser($createUser: CreateUserInput!) {
        createUser(createUser: $createUser) {
            email
            firstName
            lastName
            role
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser($updateUser: UpdateUserInput!) {
        updateUser(updateUser: $updateUser) {
            id
            active
            role
        }
    }
`;

export const GET_USERS = gql`
    query getUsers {
        getUsers {
            firstName
            lastName
            email
            role
            id
            active
            amountOfOrganisations
        }
    }
`;
