import React, { useState, useEffect } from 'react';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatISO, isValid } from 'date-fns';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        fontSize: '16px'
    },
    error: {
        color: theme.palette.error.main
    },
    helperText: {
        paddingLeft: '14px'
    },
    noValue: {
        '& input': {
            color: theme.palette.primary[50]
        }
    },
    loading: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: theme.palette.primary[50],
            zIndex: 99
        }
    },
    fullWidth: {
        width: '100%'
    },
    icon: {
        color: theme.palette.primary[500]
    }
}));

const DateField = (props) => {
    const {
        loading = false,
        initialValue = null,
        format = 'dd/MM/yyyy',
        form,
        name = '',
        hint = false,
        maxDate,
        startDate,
        fullWidth = false,
        className,
        readOnly,
        margin = 'normal'
    } = props;
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(initialValue || '');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (form && form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, name]);

    useEffect(() => {
        if (form && initialValue && !loading)
            form.onFieldChange({ key: name, value: initialValue });
        setSelectedDate(initialValue);
        // eslint-disable-next-line
    }, [loading, initialValue]);

    const handleDateChange = (data) => {
        const valid = isValid(data);
        const date = valid ? formatISO(data) : data;

        if (form) form.onFieldChange({ key: name, value: date });

        if (props.onChange) {
            props.onChange({ key: name, value: date });
        } else {
            setSelectedDate(date);
        }
    };

    return (
        <FormControl
            error={error}
            margin={margin}
            className={classNames(className, {
                [classes.fullWidth]: fullWidth
            })}
        >
            <DesktopDatePicker
                autoOk
                inputVariant="outlined"
                maxDate={maxDate}
                disabled={readOnly}
                inputFormat={format}
                value={selectedDate || new Date()}
                initialFocusedDate={startDate}
                error={error}
                renderInput={(params) => <TextField {...params} />}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => handleDateChange(date)}
                className={classNames(
                    classes.datePicker,
                    { [classes.noValue]: !selectedDate },
                    { [classes.loading]: loading }
                )}
                maxDateMessage={t('DATE.maxLengthError')}
                minDateMessage={t('DATE.minLengthError')}
                invalidDateMessage={selectedDate ? t('DATE.invalidDateMessage') : ''}
            />
            <div className={classes.helperText}>
                {error && <FormHelperText>{error && errorMessage}</FormHelperText>}
                {hint && <FormHelperText>{hint}</FormHelperText>}
            </div>
        </FormControl>
    );
};

export default DateField;
